import {userConstants} from "../../constants";

const INITIAL_STATE = {
    announcements: [],
    announcement: {},
    unAcknowledgedCount: 0
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case userConstants.ACTION_GET_ANNOUNCEMENTS:
            let unAckCount = 0;
            if (localStorage.getItem('user')) {
                const user = JSON.parse(localStorage.getItem('user'));
                action.payload.forEach(announcement => {
                        const acknowledged = announcement.acknowledge.some(ack => ack.user === user._id);
                        unAckCount += !acknowledged ? 1 : 0;
                    }
                );
            }
            return {
                ...state,
                announcements: action.payload,
                unAcknowledgedCount: unAckCount
            };
        case userConstants.ACTION_GET_ONE_ANNOUNCEMENT:
            return {
                ...state,
                announcement: action.payload
            };
        case userConstants.ACTION_CREATE_ANNOUNCEMENT_ACK:
            return {
                ...state,
                announcement: action.payload
            };
        default:
            return state;
    }
}
