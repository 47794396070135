import React from 'react';
import {connect} from "react-redux";
import {getEmployees} from '../../../../actions/admin/employee.actions';
import DataTable from 'react-data-table-component';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";

class EmployeesList extends React.Component {

    componentDidMount() {
        this.props.getEmployees();
    }

    editEmployee = (groupId) => () => {
        this.props.history.push(`/admin/employees/edit?employeeId=${groupId}`)
    };

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.AccountType,
                cell: row => row.org[0].isAdmin? <Badge variant="success">Admin</Badge>  : null 

            },
            {
                name: lang.Username,
                selector: 'userName',
                sortable: true,
            },
            {
                name: lang.Email,
                selector: 'email',
                sortable: true,
            },

            {
                name: lang.Contact,
                selector: 'contact',
                sortable: true,
            },
            {
                name: lang.Outlet,
                cell: row => row.outlet.map(outlet => outlet.name).join(',')
            },
            {
                name: lang.Supervisor,
                cell: row => {
                    return row.org[0].employee?.otSupervisor.map(sup => sup.userName).join(",")
                }
            },
            {
                name: lang.LeaveManager,
                cell: row => {
                    return row.org[0].employee?.leaveManager.map(manager => manager.userName).join(",")
                }
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>  {row.status.toLowerCase() === 'a' || row.status.toLowerCase() === 'active' ? <Badge variant="success">{lang.Active}</Badge> :
                        row.status.toLowerCase() === 'pre-active' ? <Badge variant="secondary">{lang.ActivationPending}</Badge> :
                            <Badge variant="danger">{lang.Inactive}</Badge>}
                    </h6>
                )
            },
            {
                name: '',
                button: true,
                cell: row => (
                    <>
                        <i className="fas fa-user-edit cursor-pointer text-info mr-2"
                           data-toggle="tooltip"
                           data-placement="top"
                           title="Edit User"
                           onClick={this.editEmployee(row._id)}/> | &nbsp;
                        {/*<i className="far fa-trash-alt cursor-pointer text-danger"*/}
                        {/*   data-toggle="tooltip"*/}
                        {/*   data-placement="top"*/}
                        {/*   title="Delete User"/>*/}
                    </>)
            }
        ];
    };

    render() {
        const {employees, lang} = this.props;
    
debugger
        return (
            <Container fluid className="pt-4">
                <Card>
                    <Card.Body>
                        <DataTable
                            title={lang.AllEmployees}
                            columns={this.renderColumns()}
                            data={employees}
                            pagination={true}
                        />
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = ({admin: {employeeReducer}, langReducer}) => ({
    employees: employeeReducer.employees,
    lang: langReducer.lang
});


export default connect(mapStateToProps, ({getEmployees}))(EmployeesList);
