export const adminConstants = {
    GET_GROUPS: 'GET_GROUPS',
    GET_GROUP: 'GET_GROUP',
    ADD_GROUP: 'ADD_GROUP',
    UPDATE_GROUP: 'UPDATE_GROUP',
    DELETE_GROUP: 'DELETE_GROUP',

    GET_OUTLETS: 'GET_OUTLETS',
    GET_OUTLET: 'GET_OUTLET',
    ADD_OUTLET: 'ADD_OUTLET',
    UPDATE_OUTLET: 'UPDATE_OUTLET',
    DELETE_OUTLET: 'DELETE_OUTLET',

    GET_EMPLOYEES: 'GET_EMPLOYEES',
    GET_EMPLOYEE: 'GET_EMPLOYEE',
    ADD_EMPLOYEE: 'ADD_EMPLOYEE',
    UPDATE_EMPLOYEE: 'UPDATE_EMPLOYEE',
    DELETE_EMPLOYEE: 'DELETE_EMPLOYEE',

    CREATE_ANNOUNCEMENT: 'CREATE_ANNOUNCEMENT',
    GET_ANNOUNCEMENTS: 'GET_ANNOUNCEMENTS',
    GET_ONE_ANNOUNCEMENT: 'GET_ONE_ANNOUNCEMENT',

    CREATE_CONTENT: 'CREATE_CONTENT',
    GET_CONTENTS: 'GET_CONTENT',
    GET_ONE_CONTENT: 'GET_ONE_CONTENT',

    GET_ADMIN_ORGANIZATIONS: 'GET_ADMIN_ORGANIZATIONS',
    CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
    ACTION_UPDATE_ORGANIZATION: "ACTION_UPDATE_ORGANIZATION",

    GET_ATTENDANCE_LIST: 'GET_ATTENDANCE_LIST',
    GET_PENDING_ACK_ATTENDANCES: "GET_PENDING_ACK_ATTENDANCES",
    ACTION_ADMIN_ACK_EMPLOYEE_ATTENDANCE: "ACTION_ADMIN_ACK_EMPLOYEE_ATTENDANCE",
    SET_SELECTED_ATTENDANCE: "SET_SELECTED_ATTENDANCE",
    ACTION_APPROVE_ATTENDANCE_RECORD: "ACTION_APPROVE_ATTENDANCE_RECORD",
    GET_ATTENDANCE_ONE: "GET_ATTENDANCE_ONE",
    UPDATE_ATTENDANCE_ONE: "UPDATE_ATTENDANCE_ONE",

    ACTION_CREATE_CUSTOM_FIELD: "ACTION_CREATE_CUSTOM_FIELD",
    ACTION_GET_CUSTOM_FIELDS: "ACTION_GET_CUSTOM_FIELDS",
    ACTION_UPDATE_CUSTOM_FILED: "ACTION_UPDATE_CUSTOM_FILED",

    CREATE_LEAVE_TYPE: "CREATE_LEAVE_TYPE",
    UPDATE_LEAVE_TYPE: "UPDATE_LEAVE_TYPE",
    GET_ALL_LEAVE_TYPES: "GET_ALL_LEAVE_TYPES",

    GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA"

};
