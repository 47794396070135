import {combineReducers} from "redux";
import attendanceReducer from './attendance.reducer';
import announcementReducer from './announcement.reducer';
import activityLogReducer from './activity-log.reducer';
import outletReducer from './outlet.reducer';
import ARVO_Reducer from './attendance-verification-options.reducer';


export default combineReducers({
    attendanceReducer,
    announcementReducer,
    activityLogReducer,
    outletReducer,
    ARVO_Reducer
});
