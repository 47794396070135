import {adminConstants} from "../../constants";

const initialState = {
    outlets: [],
    outlet: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case adminConstants.GET_OUTLETS:
            return {
                ...state,
                outlets: action.payload
            };
        case adminConstants.GET_OUTLET:
            return {
                ...state,
                outlet: action.payload
            };

        case adminConstants.UPDATE_OUTLET:
            return {
                ...state,
                outlets: state.outlets.map(outlet => outlet._id === action.payload._id ? (outlet = action.payload) : outlet)
            };
        case adminConstants.ADD_OUTLET:
            return {
                ...state,
                outlets: [action.payload, ...state.outlets]
            };

        case adminConstants.DELETE_OUTLET:
            return {
                ...state,
                outlets: state.outlets.filter(outlet => outlet._id !== action.payload)
            };

        default:
            return state;
    }
}
