import axios from "../axiosConfig";
import {adminConstants} from "../../constants";
import {trackPromise} from "react-promise-tracker";

export const actionCreateCustomField = (data) => async dispatch => {
    try {
        const response = await trackPromise(axios.post('customField/create', data));
        dispatch({type: adminConstants.ACTION_CREATE_CUSTOM_FIELD, payload: response.data.data});
        return response;
    } catch (e) {

    }
};

export const actionGetAttendanceVerificationOptions = () => async dispatch => {
    const response = await trackPromise(axios.get('customField/list'));
    dispatch({type: adminConstants.ACTION_GET_CUSTOM_FIELDS, payload: response.data.data});
    return response
};

export const actionUpdateAttendanceVerificationOption = (customFieldId, data) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`customField/list/edit/${customFieldId}`, data));
        dispatch({type: adminConstants.ACTION_UPDATE_CUSTOM_FILED, payload: response.data.data});
        return response
    } catch (e) {

    }
};
