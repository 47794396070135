import React from 'react';
import {connect} from "react-redux";
import {actionGetOneContent} from "../../../../actions/admin/content.actions";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class ViewContent extends React.Component {

    componentDidMount() {
        const {search} = this.props.location;
        const queryParams = new URLSearchParams(search);
        if (queryParams.get('id')) {
            const contentId = queryParams.get('id');
            this.props.actionGetOneContent(contentId);
        }

    }

    render() {
        const {content: {content = {}}, lang} = this.props;
        return (
            <Container fluid>
                <Card className="mt-5">
                    <Card.Body>
                        <Card.Title>{lang.ViewContent}</Card.Title>
                        <Row className='form-group'>
                            <Col md={3}>
                                <span className="font-weight-bold">{lang.Title}</span>
                            </Col>
                            <Col md={9}>{content.title}</Col>
                        </Row>
                        <Row className="form-group">
                            <Col md={3}>
                                <span className="font-weight-bold">{lang.Description}</span>
                            </Col>
                            <Col md={9}>
                                <div dangerouslySetInnerHTML={{__html: content.description}}/>
                            </Col>
                        </Row>
                    </Card.Body>

                </Card>
            </Container>
        )
    }
}

const mapStateToProps = ({admin: {contentReducer}, langReducer}) => ({
    content: contentReducer.content,
    lang: langReducer.lang
});

export default connect(mapStateToProps, ({actionGetOneContent}))(ViewContent)
