import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DateTimePicker from 'react-datetime-picker';

import {CustomDatePicker} from "../../../../../shared/custom-date-picker";
import {useSelector} from "react-redux";

export const UserCommentControl = (props) => {

    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState(false);
    const [punchInDateTime, setPunchInDateTime] = useState();
    const [punchOutDateTime, setPunchOutDateTime] = useState();

    const {attendanceDetail} = useSelector(state => state.user.attendanceReducer);

    const {editingComment} = props;

    useEffect(() => {
        setPunchInDateTime(new Date(attendanceDetail.punchInDateTime));
        setPunchOutDateTime(new Date(attendanceDetail.punchOutDateTime));

        if (editingComment) {
            setPunchInDateTime(new Date(editingComment.punchInDateTime));
            setPunchOutDateTime(new Date(editingComment.punchOutDateTime));
            setComment(editingComment.comment);
        }

    }, [attendanceDetail, editingComment]);


    const responseToChangeRequest = () => {
        const data = {
            status: 'pending',
            comment,
            punchInDateTime,
            punchOutDateTime
        };

        if (editingComment) {
            data._id = editingComment._id;
        }

        props.handlePostResponse(data);
        setComment("");
        setCommentError(false);
    };


    return (
        <>
            <hr/>
            <Row>
                <Col md={6}>
                    <label>Comments</label>
                    <textarea rows={3} className="form-control" value={comment}
                              onChange={(e) => setComment(e.target.value)}/>
                    {commentError && <small className="text-danger">Comments are required</small>}
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={3}>
                    <label>PunchIn Time</label>
                    <DateTimePicker
                        format="dd-MMM-yyyy HH:mm:ss"
                        maxDate={new Date()}
                        value={punchInDateTime}
                        onChange={date => setPunchInDateTime(date)}
                        customInput={<CustomDatePicker/>}
                        //  showTimeSelect
                        //  showTimeSelectOnly
                        //  timeIntervals={5}
                        timeCaption="Time"
                    />
                </Col>
                <Col md={3}>
                    <label>PunchOut Time</label>
                    <DateTimePicker
                        format="dd-MMM-yyyy HH:mm:ss"
                        maxDate={new Date()}
                        value={punchOutDateTime}
                        onChange={date => setPunchOutDateTime(date)}
                        customInput={<CustomDatePicker/>}
                        //  showTimeSelect
                        //  showTimeSelectOnly
                        //  timeIntervals={5}
                        //timeCaption="Time"
                    />
                </Col>
                <Col xs="12">
                    <Button size="sm" className="mr-1"
                            onClick={() => responseToChangeRequest("modified")}>Save</Button>
                </Col>

            </Row>
        </>
    );
};
