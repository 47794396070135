import {adminConstants} from "../../constants";

const initialState = {
    leaveTypes: [],
    leaveType: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case adminConstants.CREATE_LEAVE_TYPE:
            return {
                ...state,
                leaveType: action.payload
            };
        case adminConstants.UPDATE_LEAVE_TYPE:
            return {
                ...state,
                leaveType: action.payload
            };
        case adminConstants.GET_ALL_LEAVE_TYPES:
            return {
                ...state,
                leaveTypes: action.payload
            };
        default:
            return state;
    }
}
