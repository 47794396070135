import React from 'react';
import {connect} from "react-redux";
import {actionGetAnnouncements} from "../../../../actions/admin/announcement.actions";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import {convertDateToCustomFormat} from "../../../../utlis";

class AdminAnnouncement extends React.Component {


    componentDidMount() {
        this.props.actionGetAnnouncements();
    }

    renderTableColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.Title,
                selector: 'title',
                sortable: true,
            },
            {
                name: lang.FromDate,
                selector: 'fromDate',
                sortable: true,
                cell: row => (
                    convertDateToCustomFormat(row.fromDate)
                )
            },
            {
                name: lang.ToDate,
                selector: 'tillDate',
                sortable: true,
                cell: row => (
                    convertDateToCustomFormat(row.tillDate)
                )
            },
            {
                name: lang.Actions,
                button: true,
                cell: row => (
                    <>
                        <i className="fas fa-eye cursor-pointer mr-1 text-info"
                           onClick={() => this.onViewAnnouncementDetails(row._id)}/>|
                        <i className="far fa-trash-alt cursor-pointer text-danger ml-1"/>
                    </>)

            }
        ];
    };

    onViewAnnouncementDetails = (announcementId) => {
        this.props.history.push(`/admin/announcements/view?id=${announcementId}`);
    };

    render() {
        const {announcements, lang} = this.props;

        return (
            <Container fluid className="pt-4">
                <Card>
                    <Card.Body>
                        <DataTable
                            title={lang.Announcements}
                            columns={this.renderTableColumns()}
                            data={announcements}
                            pagination={true}
                        />
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = ({admin: {announcementReducer}, langReducer}) => ({
    announcements: announcementReducer.announcements,
    lang: langReducer.lang
});

export default connect(mapStateToProps, {actionGetAnnouncements})(AdminAnnouncement);
