import moment from "moment"

export function secondsToHMS(secs) {
    function z(n) {
        return (n < 10 ? '0' : '') + n.toFixed(0);
    }

    var sign = secs < 0 ? '-' : '';
    secs = Math.abs(secs);
    return sign + z(secs / 3600 | 0) + ':' + z((secs % 3600) / 60 | 0) + ':' + z(secs % 60);
}

export function convertTimeTo12HFormat(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}


export function getTimeFromISO_StringDate(stringDate) {
    const dateTime = new Date(stringDate);

    let hour = dateTime.getHours();
    let mins = dateTime.getMinutes();
    let secs = dateTime.getSeconds();

    hour = (hour < 10 ? '0' : '') + hour;
    mins = (mins < 10 ? '0' : '') + mins;
    secs = (secs < 10 ? '0' : '') + secs;

    return `${hour}:${mins}:${secs}`;
}

export const convertDateToCustomFormat = (ISO_String) => {
    if (ISO_String) {
        return moment(ISO_String).format("DD-MM-YYYY HH:mm:ss");
    }
    return '';
};

export const convertDateTimeToCustomFormat = (ISO_String) => {
    return moment(ISO_String).format("DD-MM-YYYY HH:mm:ss");
    // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // const date = new Date(ISO_String);
    // let day = date.getDate();
    // day = (day < 10 ? '0' : '') + day;
    // const month = months[date.getMonth()];
    // const year = date.getFullYear();
    //
    // const time12H = convertTimeTo12HFormat(getTimeFromISO_StringDate(ISO_String));
    //
    // return `${day}-${month}-${year} | ${time12H}`;
};

export const calculateTimeDiff = (endDateTimeISO, startDateTimeISO) => {
    const endDateTime = new Date(endDateTimeISO);
    const startDateTime = new Date(startDateTimeISO);
    const diffInMilliSeconds = endDateTime - startDateTime;
    return secondsToHMS(diffInMilliSeconds / 1000);
};

export const toFormData = (obj, form, namespace) => {
    const fd = form || new FormData();
    let formKey;

    for (const property in obj) {

        if (typeof obj[property] === 'boolean') {
            obj[property] = obj[property].toString();
        }

        if (obj.hasOwnProperty(property) && obj[property]) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File, use recursivity.
            if (obj[property] instanceof Date) {
                fd.append(formKey, obj[property].toISOString());
            } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                this.toFormData(obj[property], fd, formKey);
            } else { // if it's a string or a File object
                fd.append(formKey, obj[property]);
            }
        }
    }

    return fd;
};
