import React, {useEffect, useState} from "react";
import Webcam from "react-webcam";
// import { isAndroid, isIOS, isChrome, isSafari } from "react-device-detect";
import imageCompression from "browser-image-compression";
import Button from "react-bootstrap/Button";


const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const Camera = (props) => {

  const webcamRef = React.createRef();
  const {handleFileChange} = props;
  const [url ,setUrl] = useState(null);
  const [NewZipPic ,setNewZipPic] = useState(null);
  const [URLArray ,setURLArray] = useState([]);
  const [isCapture ,setisCapture] = useState(true);


   useEffect(()=>{
    if (navigator.permissions && navigator.permissions.query) {
        navigator.permissions
        .query({ name: "camera" })
        .then((permissionObj) => {
          if (permissionObj.state !== "granted") {
            alert("Show Alert box, teaching how to allow camera");
          }
          //console.log(permissionObj.state);
        })
        .catch((error) => {
          console.log("Got error :", error);
        });
    }

   })

   const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    compressPhoto(imageSrc);
  };

  const handleFile =(file) =>{
    handleFileChange(file);
  }
  const reCapturePhoto =() => {
    setisCapture(true);    
  }
  const  compressPhoto = (pic) => {
    var options = {
      maxSizeMB: 0.5,
      // maxWidthOrHeight: 200,
      useWebWorker: true
      // fileType: "image/png"
    };

    imageCompression
      .getFilefromDataUrl(pic)
      .then((file) =>{handleFile(file); return imageCompression(file ,options) })
      .then(toBase64)
      .then((newZipPic) => {
         setUrl(pic);
         setNewZipPic(newZipPic);
         setisCapture(false);
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

    return (
      <>
      {
          isCapture &&(
              <>   <Webcam
                  ref={webcamRef}
                  audio={false}
                  screenshotQuality={1} // set the Quality of camera (0-1)
                  forceScreenshotSourceSize
                  screenshotFormat="image/jpeg"
                  height="300px" // size of displaying screen
                  width="400px" // size of displaying screen
                />
                <Button variant="primary" size="sm" onClick={capturePhoto}>Capture</Button>
            </>
          )
      }
      {
          !isCapture &&(
            <>
            <img width='400px' height='400px' style={{margin:8}} src={`${NewZipPic}`}/> 
            <Button variant="primary" size="sm" onClick={reCapturePhoto}>ReCapture </Button>
            </>
          )
      }
        
      </>
    );
}

export default Camera;
