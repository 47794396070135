import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {userConstants} from "../../../../../constants";
import {convertTimeTo12HFormat, getTimeFromISO_StringDate} from "../../../../../utlis";

export const PunchBreak = (props) => {

    const [activeBreakTime, setActiveBreakTime] = useState('');

    const {breakTimes, activeSession, handleModalShow, lang} = props;

    useEffect(() => {
        if (breakTimes.length) {
            const found = breakTimes.find(br => br.startTime && !br.endTime);
            if (found) {
                setActiveBreakTime(found.startTime);
            } else {
                setActiveBreakTime('');
            }
        }
    }, [breakTimes, activeSession]);

    return (
        <div className="d-flex bg-primary text-white shadow " >
            <span className="px-4 my-auto" style={{fontSize: '45px'}}>
                <i className="fas fa-hourglass-half"/>
            </span>
            <div className="py-2 flex-grow-1">
                <span>{lang.GO_FOR_BREAK}</span> <br/>
                {activeBreakTime ?
                    <span className="font-weight-bold">
                        {convertTimeTo12HFormat(getTimeFromISO_StringDate(activeBreakTime))}
                    </span> :
                    <span className="font-weight-bold">{breakTimes.length} {lang.BreaksTaken}</span>}

                <div className="border my-2"/>
                {!activeBreakTime ?
                    <Button variant="light" size="sm" disabled={!activeSession}
                            onClick={() => handleModalShow(userConstants.BREAK_START)}>
                        {lang.GoForBreak}
                    </Button>
                    :
                    <Button variant="light" size="sm" onClick={() => handleModalShow(userConstants.BREAK_END)}>
                        {lang.ReturnFromBreak}
                    </Button>
                }

            </div>
        </div>
    )
};
