import React from "react";
import {calculateTimeDiff, convertDateTimeToCustomFormat} from "../../../../../utlis";
import {authenticationConstants, userConstants} from "../../../../../constants";
import Badge from "react-bootstrap/Badge";
import DataTable from "react-data-table-component";
import {useSelector} from "react-redux";
import moment from "moment";

const AttendanceTable = (props) => {
    const {data} = props;
    const {lang} = useSelector(state => state.langReducer);


    const calculateTotalTime = (record) => {
        const startTime = record.ifLate ? record.changeRequest?.adjustedTime.punchInDateTime : record.punchInDateTime;
        const endTime = record.punchOutDateTime ? record.ifLate ? record.changeRequest?.adjustedTime.punchOutDateTime : record.punchOutDateTime : (new Date()).toISOString();
        return calculateTimeDiff(endTime, startTime);
    };
   
    const customStyles = {
        header: {
          style: {
            minHeight: '50px',
        
          },
        },
        headRow: {
          style: {
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
           
          },
        },
        headCells: {
          style: {
         
            '&:not(:last-of-type)': {
                
                
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
              
            },
          },
        },
        cells: {
          style: {
            
            '&:not(:last-of-type)': {
                
              borderRightStyle: 'solid',
              borderRightWidth: '1px',
            },
          },
        },
      };
      const style = {
        
         

      }

    const renderColumns = () => {

        return [
            {
                name: lang.Name,
            
                cell: row => row.user?.firstName,
                style: {
                    '&:not(:last-of-type)' : {
                        width: '20px',
                    }
                    
                },
    
                allowOverflow: true,
               
            },
            {
                name: lang.Outlet,
                selector: 'outlet',
        
                allowOverflow: true,
                sortable: true,
                cell: row => row.outlet?.name,
                wrap: false
            },
            {
                name: lang.PunchInTime,
                selector: 'punchInDateTime',
                sortable: true,
            
                allowOverflow: true,
                cell: row => {
                    if (!row.ifLate) {
                        return (
                            <span >
                                {moment(row.punchInDateTime).format("DD/MM/YYYY | HH:mm")}
                            </span>
                        )
                    } else {
                        return <span>
                            {moment(row.changeRequest?.adjustedTime.punchInDateTime).format("DD/MM/YYYY | HH:mm")}
                        </span>
                    }
                }
            },
            {
                name: lang.PunchOutTime,
                selector: 'punchOutDateTime',
                sortable: true,
                
                allowOverflow: true,
                cell: row => {
                    if (row.ifLate) {

                        return <span >
                            {row.punchOutDateTime ? moment(row.changeRequest?.adjustedTime.punchOutDateTime).format("DD/MM/YYYY | HH:mm") : '-----'}
                            </span>

                    } else {
                        return <span>
                            {row.punchOutDateTime ? moment(row.punchOutDateTime).format("DD/MM/YYYY | HH:mm") : '-----'}
                            </span>
                    }
                }
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                
                allowOverflow: true,
                          
                cell: row => (
                    <h6>
                        {row.status === userConstants.PUNCH_IN && <Badge variant="success">{lang.PunchedIn}</Badge>}
                        {row.status === userConstants.PUNCH_OUT && <Badge variant="light">{lang.PunchedOut}</Badge>}
                        {row.status === userConstants.BREAK_START && <Badge variant="warning">{lang.OnBreak}</Badge>}
                    </h6>
                )
            },
            {
                name: lang.TotalHours,
                sortable: true,
                
                allowOverflow: true,
                cell: row => {
                    return calculateTotalTime(row);
                }
            },
            {
                name: lang.Photos,
                button: true,
              
                allowOverflow: true,
                cell: row => (
                    <span
                        className="btn btn-sm btn-info"
                        onClick={() => props.showAttendanceDetailsModal(row)}>
                           <i className="fas fa-camera"/>
                    </span>
                )
            }
        ];
    };

    return (
        <DataTable className= "App"
          customStyles = {customStyles}
            columns={renderColumns()}
            data={data}
            pagination={true}
            highlightOnHover
            pointerOnHover
            style =  {style}
            onRowClicked={props.navigateToAttendanceDetail}
        />
    );
};

export default AttendanceTable;
