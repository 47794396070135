import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const WriteAcknowledgementModal = (props) => {
    const {showModal, handleCloseModal, submitAcknowledgement, lang} = props;
    const [file, setFile] = useState('');
    const [fileError, setFileError] = useState(false);
    const [acknowledgement, setAcknowledgement] = useState('');


    const handleFileChange = (event) => {
        setFile(event.target.files[0])
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            event.stopPropagation();
            return;
        }

        if (!file) {
            setFileError(true);
            return false;
        }

        const formData = new FormData();

        formData.append('image', file, file.name);
        formData.append('acknowledgement', acknowledgement);
        submitAcknowledgement(formData);
    };


    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{lang.WriteAcknowledgement}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form noValidate onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>{lang.UploadImage}</label>
                        <input className="form-control" type="file" onChange={handleFileChange} accept="image/*"
                               required/>
                        {fileError && <small className="text-danger">Picture is required</small>}
                    </div>
                    <div className="form-group">
                        <label>{lang.Acknowledgement}</label>
                        <textarea className="form-control" value={acknowledgement}
                                  onChange={event => setAcknowledgement(event.target.value)}/>
                    </div>
                </form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleCloseModal}>Close</Button>
                <Button variant="primary" size="sm" onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
};
