import {adminConstants} from "../../constants";


const initialState = {
    dashboardCounts: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case adminConstants.GET_DASHBOARD_DATA:
            return {
                ...state,
                dashboardCounts: action.payload
            };
            default:
                return state;
        }


}