import React from 'react';
import {connect} from "react-redux";
import {convertDateToCustomFormat} from "../../../../utlis";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import {actionGetAnnouncements} from "../../../../actions/user/announcement.actions";
import Badge from "react-bootstrap/Badge";

class UserAnnouncements extends React.Component {

    componentDidMount() {
        const {actionGetAnnouncements, loggedInUser} = this.props;
        actionGetAnnouncements(loggedInUser._id);
    }

    onViewAnnouncementDetails = (announcementId) => {
        this.props.history.push(`/user/announcements/view?id=${announcementId}`);
    };

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.Title,
                selector: 'title',
                sortable: true,
            },
            {
                name: lang.FormDate,
                selector: 'fromDate',
                sortable: true,
                cell: row => (
                    convertDateToCustomFormat(row.fromDate)
                )
            },
            {
                name: lang.ToDate,
                selector: 'tillDate',
                sortable: true,
                cell: row => (
                    convertDateToCustomFormat(row.tillDate)
                )
            },
            {
                name: lang.AckStatus,
                cell: row => {
                    const {loggedInUser} = this.props;
                    if (row.acknowledge.some(ack => ack.user === loggedInUser._id)) {
                        return <h6><Badge variant={"success"}>{lang.Acknowledged}</Badge></h6>
                    } else {
                        return <h6><Badge variant={"secondary"}>{lang.Unacknowledged}</Badge></h6>
                    }
                }
            },
            {
                name: lang.Actions,
                button: true,
                cell: row => (
                    <>
                        <i className="fas fa-eye cursor-pointer mr-1 text-info"
                           onClick={() => this.onViewAnnouncementDetails(row._id)}/>
                    </>)
            }
        ];
    };

    render() {
        const {announcements, lang} = this.props;

        return (
            <Container fluid className="pt-4">
                <Card>
                    <Card.Body>
                        <DataTable
                            title={lang.AllAnnouncements}
                            columns={this.renderColumns()}
                            data={announcements}
                            pagination={true}
                        />
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = ({user: {announcementReducer}, authentication, langReducer}) => ({
    announcements: announcementReducer.announcements,
    loggedInUser: authentication.loggedInUser,
    lang: langReducer.lang
});

export default connect(mapStateToProps, ({actionGetAnnouncements}))(UserAnnouncements);
