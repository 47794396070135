import {combineReducers} from "redux";
import groupReducer from './groups.reducer';
import outletReducer from './outlets.reducer';
import employeeReducer from './employees.reducer';
import announcementReducer from './announcement.reducer';
import contentReducer from './content.reducer';
import organizationReducer from './organization.reducer';
import attendanceReducer from './attendance.reducer';
import ARVO_Reducer from './attendance-verification-options.reducer';
import leaveReducer from './leave.management.reducer';
import dashboardReducer from'./dashboard.reducer';

export default combineReducers({
    groupReducer,
    employeeReducer,
    announcementReducer,
    contentReducer,
    organizationReducer,
    outletReducer,
    attendanceReducer,
    ARVO_Reducer,
    leaveReducer,
    dashboardReducer
});
