import React from "react";
import {connect} from "react-redux";
import {actionGetAttendanceVerificationOptions} from "../../../../../../actions/admin/attendance-verification-options.actions";
import AttendanceVerificationField from "./ARVO_Field";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import AttendanceVerificationForm from "./ARVO_Field/ARVO_Form";


class AttendanceVerificationOptions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAdd_ARVO_Modal: false
        }
    }

    componentDidMount() {
        this.props.actionGetAttendanceVerificationOptions();
    }

    handleToggle_ARVO_Modal = (show) => {
        this.setState({showAdd_ARVO_Modal: show})
    };

    render() {
        const {showAdd_ARVO_Modal} = this.state;
        const {ARVO_Fields} = this.props;
        return (
            <div className="pt-3">
                <Card>
                    <Card.Body>
                        <div className="d-flex justify-content-between">
                            <h5>Attendance Record Verification Options</h5>
                            <Button size="sm" onClick={() => this.handleToggle_ARVO_Modal(true)}>Add</Button>
                        </div>
                        <div className="d-flex font-weight-bold mb-2">
                            <div className="mr-2 p-1" style={{width: "40px"}}>
                                Icon
                            </div>
                            <div className="mr-3 my-auto p-1"
                                 style={{width: "100px"}}>
                                Short Name
                            </div>
                            <div className="my-auto mr-3 p-1"
                                 style={{width: "200px"}}>
                                Name
                            </div>
                            <div className="my-auto flex-grow-1 p-1">
                                Description
                            </div>
                            <div className="my-auto p-1" style={{width: "100px"}}>
                                Status
                            </div>
                            <div className="my-auto text-center" style={{width: "20px"}}/>
                        </div>
                        {ARVO_Fields.sort((a, b) => {
                            if (a._id > b._id) return -1;
                            if (a._id < b._id) return 1;
                            return 0;
                        }).map(field => (
                            <div className="mb-2">
                                <AttendanceVerificationField data={field} key={field._id}/>
                            </div>
                        ))}
                    </Card.Body>
                </Card>

                <Modal size="lg" show={showAdd_ARVO_Modal} onHide={() => this.handleToggle_ARVO_Modal(false)}>
                    <Modal.Body>
                        <AttendanceVerificationForm handleClose={() => this.handleToggle_ARVO_Modal(false)}/>
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
}

const mapStateToProps = ({admin: {ARVO_Reducer}}) => ({
    ARVO_Fields: ARVO_Reducer.ARVO_Fields
});

export default connect(mapStateToProps, ({actionGetAttendanceVerificationOptions}))(AttendanceVerificationOptions);
