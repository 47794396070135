import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { actionCreateCustomField } from '../../../../actions/admin/attendance-verification-options.actions';
import attendanceSettingsImg from '../../../../assets/attendanceSettings.svg';
import generalSettingsImg from '../../../../assets/settings.svg';

class Settings extends React.Component {
  render() {
    return (
      <div className="row mt-3">
        <div className="col-md-3">
          <Card className="text-center cursor-pointer pt-2">
            <Card.Img
              variant="top"
              className="w-50 align-self-center"
              src={generalSettingsImg}
            />
            <Card.Body>
              <Card.Title>General Settings</Card.Title>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-3">
          <Card
            className="text-center cursor-pointer"
            onClick={() =>
              this.props.history.push('/admin/attendance/settings')
            }
          >
            <Card.Img
              variant="top"
              className="w-50 align-self-center mt-1"
              src={attendanceSettingsImg}
            />
            <Card.Body>
              <Card.Title>Attendance Settings</Card.Title>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ admin: { customFieldReducer } }) => ({});

export default connect(mapStateToProps, { actionCreateCustomField })(Settings);
