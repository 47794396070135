import {userConstants} from "../../constants";
import axios from '../axiosConfig';
import {trackPromise} from "react-promise-tracker";
import {toast} from 'react-toastify';


export const actionGetAnnouncements = (userId) => async dispatch => {

    const response = await axios.get(`employees/announcements/list`);
    dispatch({type: userConstants.ACTION_GET_ANNOUNCEMENTS, payload: response.data.data});

};


export const actionGetAnnouncement = (announcementId) => async dispatch => {
    const response = await trackPromise(axios.get(`employees/view/${announcementId}`));
    dispatch({
        type: userConstants.ACTION_GET_ONE_ANNOUNCEMENT,
        payload: response.data.data
    });
};

export const actionCreateAnnouncementAck = (formData, _id) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`employees/acknowledgement/${_id}`, formData));
        dispatch({
            type: userConstants.ACTION_CREATE_ANNOUNCEMENT_ACK,
            payload: response.data.data
        });
        toast.success('Announcement is acknowledged')
    } catch (err) {
        toast.error('Error occurred while submitting acknowledgement. Please try again');
    }
};
