const env = process.env.REACT_APP_ENV;
const LOCAL_API = "https://angel.bluesilo.com.sg/api";
const DEV_API = "https://angel-stage.bluesilo.com.sg/api";
const PROD_API = "https://angel.bluesilo.com.sg/api";
let API_URL =PROD_API ;


// switch (env) {
//     case 'local':
//         API_URL = LOCAL_API;
//         break;
//     case 'dev':
//         API_URL = DEV_API;
//         break;
//     case 'prod':
//         API_URL = PROD_API;
//         break;
//     default:
//         console.error("Invalid env type");
// }

// switch (env.trim()) {
//     case 'local':
//         API_URL = LOCAL_API;
//         break;
//     case 'dev':
//         API_URL = DEV_API;
//         break;
//     case 'prod':
//         API_URL = PROD_API;
//         break;
//     default:
//         console.error("Invalid env type");
// }


// export {API_URL};
// console.log("API_URL config", env);
