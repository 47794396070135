import React from 'react';
import * as yup from 'yup';
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {
    actionCreateCustomField,
    actionUpdateAttendanceVerificationOption
} from "../../../../../../../actions/admin/attendance-verification-options.actions";
import Card from "react-bootstrap/Card";
import Toggle from "react-toggle";
import "react-toggle/style.css"
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import {Badge} from "react-bootstrap";
import AttendanceVerificationForm from "./ARVO_Form";
import {API_URL} from "../../../../../../../config";


class AttendanceVerificationField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showForm: false
        }
    }


    render() {
        const {data} = this.props;

        return (
            <>
                {!this.state.showForm &&
                <div className="d-flex border px-2 py-3 rounded align-content-center">
                    <div className="mr-2 text-center" style={{width: "40px"}}>
                        {data?.iconType === 'predefined' ? <i className={data?.icon} style={{fontSize: "30px"}}/> :
                            <img src={`${API_URL}/${data?.icon}`} style={{height: "48px",}} alt=""/>
                        }

                    </div>
                    <div className="mr-3 font-weight-bold border border-dark rounded my-auto p-1"
                         style={{width: "100px"}}
                    >
                        {data?.shortName}
                    </div>
                    <div className="my-auto border-dark  border rounded my-auto p-1 mr-3"
                         style={{width: "200px"}}>{data?.name}</div>
                    <div className="my-auto border-dark  border rounded my-auto p-1 flex-grow-1">
                        {/*{data?.description}*/}
                        <span dangerouslySetInnerHTML={{__html: data?.description}}/>
                    </div>
                    <div className="my-auto text-center" style={{width: "100px"}}>
                        <Badge variant={data?.isActive ? 'success' : 'danger'}>
                            {data?.isActive ? 'Active' : 'Inactive'}
                        </Badge>
                    </div>
                    <div className="my-auto text-center" style={{width: "20px"}}>
                        <i className="fas fa-pencil-alt cursor-pointer"
                           onClick={() => this.setState({showForm: true})}/>
                    </div>
                </div>
                }

                {this.state.showForm &&
                <AttendanceVerificationForm data={data} handleClose={() => this.setState({showForm: false})}/>
                }

            </>
        );
    }
}

export default connect(null, ({
    actionCreateCustomField,
    actionUpdateAttendanceVerificationOption
}))(AttendanceVerificationField);
