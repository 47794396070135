import React, {Component} from "react";
import Login from '../login/Login';
import Layout from "../../layout/Layout";
import {Route, Switch} from "react-router-dom";

export class UserRoute extends Component {

    render() {

        const {match} = this.props;

        return (
            <Switch>
                <Route path={`${match.path}/login`} component={Login}/>
                <Route path={`${match.path}`} component={Layout}/>
            </Switch>
        )


        // if (location.pathname === '/user/login') {
        //     return <Login {...this.props}/>
        // } else {
        //     return (<Layout {...this.props}/>)
        // }
    }

}
