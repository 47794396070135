import React, {useEffect} from 'react';
import {Route} from 'react-router-dom';
import {AdminRoute} from "./saas-user/admin";
import {UserRoute} from "./saas-user/user";
import Login from './login/Login';
import {PrivateRoute} from "./PrivateRoute";
import {AccountConfirmation} from "./saas-user/shared/account-confirmation";
import SelectOrganization from "./saas-user/shared/select-organization";
import {useDispatch} from "react-redux";
import {commonConstants} from "../constants";
import language from "../language";

export const RootRoute = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        getLanguage();
    });

    const getLanguage = () => {
        const locale = localStorage.getItem('locale') || 'en';
        const langObj = language[locale];
        dispatch({type: commonConstants.ACTION_GET_LANG, payload: {lang: langObj.lang, emoji: langObj.emoji, locale}});
    };

    const {match} = props;
    return (
        <>
            <Route exact path={`${match.path}emailVerification`} component={AccountConfirmation}/>
            <Route exact path={`${match.url}`} component={Login}/>
            <Route exact path={`${match.url}login`} component={Login}/>
            <PrivateRoute path={`${match.url}select-organization`} component={SelectOrganization}/>
            <PrivateRoute path={`${match.url}admin`} component={AdminRoute}/>
            <PrivateRoute path={`${match.url}user`} component={UserRoute}/>
        </>
    );

};
