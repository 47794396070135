import React from 'react';
import {connect} from "react-redux";
import {getGroups} from '../../../../actions/admin/group.actions';
import DataTable from 'react-data-table-component';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/esm/Button";
import Badge from "react-bootstrap/Badge";

class GroupsList extends React.Component {

    componentDidMount() {
        this.props.getGroups();
    }

    editGroup = (groupId) => () => {
        this.props.history.push(`/admin/groups/edit?groupId=${groupId}`)
    };

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.Name,
                selector: 'name',
                sortable: true,
            },
            {
                name: lang.Description,
                selector: 'description',
                sortable: true,
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>  {row.status === 'A' ? <Badge variant="success">{lang.Active}</Badge> :
                        <Badge variant="danger">{lang.Inactive}</Badge>}
                    </h6>
                )
            },
            {
                name: '',
                button: true,
                cell: row => (
                    <>
                        <Button variant="info" size="sm" className="mr-1" onClick={this.editGroup(row._id)}><i
                            className="fas fa-wrench"/></Button>
                        {/*<Button variant="danger" size="sm"><i className="far fa-trash-alt"/></Button>*/}
                    </>)
            }
        ];
    };

    render() {
        const {groups, lang} = this.props;
        return (
            <Container fluid className="pt-4">
                <Card>
                    <Card.Body>
                        <DataTable
                            title={lang.AllGroups}
                            columns={this.renderColumns()}
                            data={groups}
                            pagination={true}
                        />
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = ({admin: {groupReducer}, langReducer}) => ({
    groups: groupReducer.groups,
    lang: langReducer.lang
});


export default connect(mapStateToProps, ({getGroups}))(GroupsList);
