import {adminConstants} from "../../constants";

const INITIAL_STATE = {
    ARVO_Fields: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case adminConstants.ACTION_CREATE_CUSTOM_FIELD:
            const {ARVO_Fields} = state;
            ARVO_Fields.push(action.payload);
            return {
                ...state,
                ARVO_Fields: [].concat(ARVO_Fields)
            };
        case adminConstants.ACTION_GET_CUSTOM_FIELDS:
            return {
                ...state,
                ARVO_Fields: action.payload
            };
        case adminConstants.ACTION_UPDATE_CUSTOM_FILED:
            return {
                ...state,
                ARVO_Fields: state.ARVO_Fields.map(field => field._id === action.payload._id ? field = action.payload : field)
            };
        default:
            return state;
    }
}
