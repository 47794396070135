import React from "react";
import AttendanceVerificationRecords from "../attendance-verification-records";
import AttendanceAcknowledgementRecords from "../attendance-ack-records";
import lang from "../../../../../language/en.json"

export const PendingAttendances = (props) => {
  
    return (
        <>
            <h4 className="mt-3">Attendance Records</h4>
            <AttendanceVerificationRecords status="pending" title={lang.VerifiedAttendanceRecords}/>
            <AttendanceAcknowledgementRecords status="pending" title={lang.PendingAcknowledgements}/>
        </>
    );
};
