import {userConstants} from "../../constants";

const INITIAL_STATE = {
    activityLogs: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case userConstants.ACTION_GET_ACTIVITY_LOG:
            return {
                ...state,
                activityLogs: action.payload
            };
        default:
            return state;
    }
}
