import {adminConstants, userConstants} from "./../constants";

let selectedOrg = JSON.parse(localStorage.getItem('selectedOrganization'));

const INITIAL_STATE = {
    organizations: [],
    organization: null,
    selectedOrganization: selectedOrg ? selectedOrg : null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case adminConstants.CREATE_ORGANIZATION:
            return {
                ...state,
                organizations: [...state.organizations, action.payload]
            };
        case userConstants.ACTION_GET_ORGANIZATIONS:
            const organizations = action.payload.map(record => {
                return {
                    isSuperAdmin: record.isSuperAdmin,
                    isAdmin: record.isAdmin,
                    isEmployee: record.isEmployee,
                    employee: record.employee,
                    otSupervisorOn: record.otSupervisorOn,
                    leaveManagerOn: record.leaveManagerOn,
                    ...record.orgId
                }
            });
            return {
                ...state,
                organizations
            };
        case userConstants.SET_SELECTED_ORGANIZATION:
            const found = state.organizations.find(org => org._id === action.payload);
            localStorage.setItem('selectedOrganization', JSON.stringify(found));
            return {
                ...state,
                selectedOrganization: found ? found : null
            };
        case adminConstants.ACTION_UPDATE_ORGANIZATION:
            localStorage.setItem('selectedOrganization', JSON.stringify(action.payload));
            return {
                ...state,
                organizations: state.organizations.map(org => {
                    if (org._id === action.payload._id) {
                        return action.payload
                    }
                    return org;
                }),
                selectedOrganization: action.payload
            };

        default:
            return {
                ...state
            }
    }
}
