import React from "react";
import Badge from "react-bootstrap/Badge";
import DataTable from "react-data-table-component";
import {connect} from "react-redux";
import {
    acknowledgeAttendance,
    acknowledgeCompleteAttendance,
    getAttendanceHistory
} from '../../../../actions/user/attendance.actions';
import {convertDateTimeToCustomFormat, calculateTimeDiff} from "../../../../utlis";
import {userConstants} from "../../../../constants";
import {AttendanceDetailModal} from "./attendance-detail-modal";
import {Card} from "react-bootstrap";
import {SubsAttendanceList} from "./subs-attendance-list";
import OwnAttendanceDetails from "./own";

class Attendance extends React.Component {

    constructor() {
        super();
        this.state = {
            showModal: false,
            showAttendanceVerificationModal: false,
            selectedAttendance: {},
        }
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        const attendanceFor = params.for;
        const {getAttendanceHistory} = this.props;
        getAttendanceHistory({attendanceFor});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {match: {params}, getAttendanceHistory} = this.props;
        if (prevProps.match.params.for !== params.for) {
            getAttendanceHistory({attendanceFor: params.for})
        }
    }

    calculateTotalTime = (record) => {
        const startTime = record.ifLate ? record.changeRequest?.adjustedTime.punchInDateTime : record.punchInDateTime;
        const endTime = record.punchOutDateTime ? record.ifLate ? record.changeRequest?.adjustedTime.punchOutDateTime : record.punchOutDateTime : (new Date()).toISOString();
        return calculateTimeDiff(endTime, startTime);
    };

    handleModalClose = () => {
        this.setState({showModal: false});
    };

    handleAcknowledgement = (attendanceId, data) => {
        this.props.acknowledgeAttendance(attendanceId, data);
    };

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.Name,
                cell: row => row.user.firstName
            },
            {
                name: lang.Outlet,
                selector: 'outlet',
                sortable: true,
                cell: row => row.outlet?.name
            },
            {
                name: lang.PunchInTime,
                selector: 'punchInDateTime',
                sortable: true,
                cell: row => {
                    if (!row.ifLate) {
                        return convertDateTimeToCustomFormat(row.punchInDateTime)
                    } else {
                        return convertDateTimeToCustomFormat(row.changeRequest?.adjustedTime.punchInDateTime)
                    }
                }
            },
            {
                name: lang.PunchOutTime,
                selector: 'punchOutDateTime',
                sortable: true,
                cell: row => {
                    if (row.ifLate) {
                        return row.punchOutDateTime ? convertDateTimeToCustomFormat(row.changeRequest?.adjustedTime.punchOutDateTime) : '-----'

                    } else {
                        return row.punchOutDateTime ? convertDateTimeToCustomFormat(row.punchOutDateTime) : '-----'
                    }
                }
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>
                        {row.status === userConstants.PUNCH_IN && <Badge variant="success">{lang.PunchedIn}</Badge>}
                        {row.status === userConstants.PUNCH_OUT && <Badge variant="light">{lang.PunchedOut}</Badge>}
                        {row.status === userConstants.BREAK_START && <Badge variant="warning">{lang.OnBreak}</Badge>}
                    </h6>
                )
            },
            {
                name: lang.TotalHours,
                sortable: true,
                cell: row => {
                    return this.calculateTotalTime(row);
                }
            },
            {
                name: lang.RequestStatus,
                cell: row => (
                    row.changeRequest ? (
                        <>
                            {row.changeRequest.lastStatus === 'pending' && <Badge variant="warning">Pending</Badge>}
                            {row.changeRequest.lastStatus === 'approved' && <Badge variant="success">Approved</Badge>}
                            {row.changeRequest.lastStatus === 'rejected' && <Badge variant="danger">Rejected</Badge>}
                        </>
                    ) : ''
                )
            },
            {
                name: lang.Approved,
                cell: row => row.isVerified && <i className="fas fa-check-circle text-success"/>
            },
            {
                name: lang.Photos,
                button: true,
                cell: row => (
                    <span
                        className="btn btn-sm btn-info"
                        onClick={() => {

                            this.setState({selectedAttendance: row, showModal: true});
                        }}>
                           <i className="fas fa-camera"/>
                    </span>
                )
            }
        ];
    };

    render() {

        const {showModal, selectedAttendance} = this.state;
        const {lang, acknowledgeCompleteAttendance, match: {params}} = this.props;
        return (
            <div className="mt-3">
                {params.for === 'mysub' &&
                <SubsAttendanceList
                    history={this.props.history}
                    handleAcknowledgement={this.handleAcknowledgement}/>}

                {params.for !== 'mysub' && (
                    <OwnAttendanceDetails {...this.props}/>
                )}

                <AttendanceDetailModal
                    showModal={showModal} handleModalClose={this.handleModalClose}
                    attendanceId={selectedAttendance._id} lang={lang}
                    handleAcknowledgement={this.handleAcknowledgement}
                    handleCompleteAcknowledgement={(status) => acknowledgeCompleteAttendance(selectedAttendance._id, {status})}
                />
            </div>
        );
    }
}

const mapStateToProps = ({user: {attendanceReducer}, authentication, langReducer}) => ({
    attendanceHistory: attendanceReducer.attendanceHistory,
    loggedInUser: authentication.loggedInUser,
    lang: langReducer.lang
});

export default connect(mapStateToProps, {
    getAttendanceHistory,
    acknowledgeAttendance,
    acknowledgeCompleteAttendance
})(Attendance);
