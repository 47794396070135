import React from "react";
import {Card} from "react-bootstrap";
import {AttendanceDetailModal} from "../attendance-detail-modal";
import AttendanceTable from "./AttendanceTable"
import {connect} from "react-redux";
import {
    acknowledgeAttendance,
    acknowledgeCompleteAttendance,
    getAttendanceHistory
} from "../../../../../actions/user/attendance.actions";
import moment from 'moment'

class OwnAttendanceDetails extends React.Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            selectedAttendance: {},
            pendingChangeRequests: [],
            pendingVerifications: [],
            verifiedRecords: []
        }
    }

    componentDidMount() {
        const {getAttendanceHistory} = this.props;
        getAttendanceHistory({attendanceFor: "Own"});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.attendanceHistory !== this.props.attendanceHistory) {
            const {attendanceHistory} = this.props;
       

            const pendingChangeRequests = attendanceHistory?.filter(attendance => attendance.changeRequest?.lastStatus === 'pending');
            const pendingVerifications = attendanceHistory?.filter(attendance =>  !attendance.isVerified );
            const verifiedRecords = attendanceHistory?.filter(attendance => attendance.isVerified);

            this.setState({pendingChangeRequests, pendingVerifications, verifiedRecords});

        }
    }

    render() {

        
        const {showModal, selectedAttendance, pendingChangeRequests, pendingVerifications, verifiedRecords} = this.state;
        
        const {lang} = this.props;

        return (
            <div className="mt-3">
                <Card>
                    <Card.Body>
                        <Card.Title>{lang.MyPendingVerifications}</Card.Title>
                        <AttendanceTable
                            data={pendingVerifications}
                            navigateToAttendanceDetail={(row) => this.props.history.push(`/user/attendance/view/${row._id}`)}
                            showAttendanceDetailsModal={(row) => this.setState({
                                showModal: true,
                                selectedAttendance: row
                            })}

                        />
                    </Card.Body>
                </Card>

                <Card className="mt-2">
                    <Card.Body>
                        <Card.Title>{lang.MyPendingChangeRequests}</Card.Title>
                        <AttendanceTable
                            data={pendingChangeRequests}
                            navigateToAttendanceDetail={(row) => this.props.history.push(`/user/attendance/view/${row._id}`)}
                            showAttendanceDetailsModal={(row) => this.setState({
                                showModal: true,
                                selectedAttendance: row
                            })}

                        />
                    </Card.Body>
                </Card>

                <Card className="mt-2">
                    <Card.Body>
                        <Card.Title>{lang.MyVerifiedAttendances}</Card.Title>
                        <AttendanceTable
                            data={verifiedRecords}
                            navigateToAttendanceDetail={(row) => this.props.history.push(`/user/attendance/view/${row._id}`)}
                            showAttendanceDetailsModal={(row) => this.setState({
                                showModal: true,
                                selectedAttendance: row
                            })}

                        />
                    </Card.Body>
                </Card>

                <AttendanceDetailModal
                    showModal={showModal} handleModalClose={() => this.setState({showModal: false})}
                    attendanceId={selectedAttendance._id} lang={lang}
                    handleAcknowledgement={this.handleAcknowledgement}
                    handleCompleteAcknowledgement={(status) => acknowledgeCompleteAttendance(selectedAttendance._id, {status})}
                />
            </div>
        );
    }
}

const mapStateToProps = ({user: {attendanceReducer}, authentication, langReducer}) => ({
    attendanceHistory: attendanceReducer.attendanceHistory,
    loggedInUser: authentication.loggedInUser,
    lang: langReducer.lang
});

export default connect(mapStateToProps, {
    getAttendanceHistory,
    acknowledgeAttendance,
    acknowledgeCompleteAttendance
})(OwnAttendanceDetails);
