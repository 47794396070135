import {userConstants} from "../../constants";

const initialState = {
    attendanceHistory: [],
    activeSession: {},
    breakTimes: [],
    breakTimeDetail: [],
    attendanceDetail: {},
    breakTimeDetails:{},
    selectedAttendance: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_ACTIVE_ATTENDANCE_SESSION:
            return {
                ...state,
                activeSession: action.payload,
                breakTimes: action.payload ? action.payload.breakTime : [],
                // activeBreakTime: action.payload.breakTime.length>0
            };
        case userConstants.END_ATTENDANCE_SESSION:
            return {
                ...state,
                activeSession: null,
                breakTimes: []
            };
        case userConstants.START_NEW_ATTENDANCE_SESSION:
            return {
                ...state,
                activeSession: action.payload,
                breakTimes: action.payload ? action.payload.breakTime : []
            };
        case userConstants.BREAK_START:
            return {
                ...state,
                breakTimes: action.payload.breakTime
            };
        case userConstants.BREAK_END:
            return {
                ...state,
                breakTimes: action.payload.breakTime
            };
        case userConstants.GET_ATTENDANCE_HISTORY:
            return {
                ...state,
                attendanceHistory: action.payload
            };
        case userConstants.GET_ATTENDANCE_DETAILS:
            return {
                ...state,
                attendanceDetail: action.payload
            };
        case userConstants.CREATE_ATTENDANCE_CHANGE_REQUEST:
            return {
                ...state,
                attendanceDetail: action.payload
            };
        case userConstants.CREATE_BREAKTIME_CHANGE_REQUEST:
            return {
                ...state,
                breakTimeDetails: action.payload
            };
        case userConstants.GET_BREAKTIME_CHANGE_REQUEST:
            return {
                ...state,
                breakTimeDetail: action.payload
            };
        case userConstants.UPDATE_BREAKTIME_ATTENDANCE_CHANGE_REQUEST:
            const {breakTimeDetails} = state;
            breakTimeDetails.btchangeRequest = action.payload;
            return {
                ...state,
                attendanceDetail: {...attendanceDetail}
            };
        case userConstants.UPDATE_ATTENDANCE_CHANGE_REQUEST:
            const {attendanceDetail} = state;
            attendanceDetail.changeRequest = action.payload;
            return {
                ...state,
                attendanceDetail: {...attendanceDetail}
            };
        case userConstants.ACTION_ACK_EMPLOYEE_ATTENDANCE:

            return {
                ...state,
                selectedAttendance: action.payload,
                attendanceHistory: [].concat(state.attendanceHistory.map(attendance => attendance._id === action.payload._id ? action.payload : attendance))
            };
        case userConstants.ACTION_APPROVE_ATTENDANCE_RECORD:
            return {
                ...state,
                attendanceHistory: [].concat(state.attendanceHistory.map(attendance => attendance._id === action.payload._id ? action.payload : attendance))
            };
        case userConstants.UPDATE_ATTENDANCE_COMMENT:
            return {
                ...state,
                attendanceDetail: action.payload
            };

        case userConstants.SET_EMPLOYEE_SELECTED_ATTENDANCE:
        
            return {
                ...state,
                selectedAttendance: action.payload
            };
        default:
            return state;
    }
}
