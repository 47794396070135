import React, {Component} from "react";
import Login from '../login/Login';
import Layout from "../../layout/Layout";

export class AdminRoute extends Component {

    render() {

        const {location} = this.props;

        if (location.pathname === '/admin/login') {
            return <Login {...this.props}/>
        } else {
            return (<Layout {...this.props}/>)
        }
    }

}
