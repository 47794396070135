import React from "react";

export default class AccountSettings extends React.Component {
    render() {
        return (
            <h1>
                Account Settings
            </h1>
        );
    }
}
