import React, {useEffect, useState} from 'react';
import Button from "react-bootstrap/Button";
import {userConstants} from "../../../../../constants";
import {secondsToHMS} from "../../../../../utlis";
import {useSelector} from "react-redux";

export const PunchAttendance = (props) => {

    const [timer, setTimer] = useState('00:00:00');
    const [isBreakActive, setBreakActive] = useState(false);
    const {activeSession, breakTimes, handleModalShow, lang} = props;

    useEffect(() => {
        if (breakTimes.length > 0) {
            const found = breakTimes.find(br => br.startTime && !br.endTime);
            setBreakActive(!!found)
        }
        let interval = setInterval(() => {
            const punchInDateTime = activeSession.punchInDateTime ? new Date(activeSession.punchInDateTime) : null;
            if (punchInDateTime) {
                const diffInMilliSeconds = new Date() - punchInDateTime;
                setTimer(secondsToHMS(diffInMilliSeconds / 1000));

            } else {
                setTimer('00:00:00');
            }
        }, 1000);

        if (!activeSession) {
            clearInterval(interval);
            setTimer('00:00:00');
        }
        return () => clearInterval(interval);
    }, [activeSession, breakTimes]);

    return (
        <div className="d-flex bg-info text-white shadow ">
                            <span className="px-4 my-auto" style={{fontSize: '45px'}}>
                                <i className="far fa-clock"/>
                            </span>
            <div className="py-2 flex-grow-1">
                {activeSession ? <span>{lang.PUNCH_OUT_FROM_THIS_SHIFT}</span> :
                    <span>{lang.PUNCH_IN_TO_SHIFT}</span>}
                <br/>
                <span className="font-weight-bold">
                                    {timer}
                                </span>
                <div className="border border-white my-2"/>
                {
                    activeSession ?
                        <Button
                            variant="light" size="sm"
                            onClick={() => handleModalShow(userConstants.PUNCH_OUT)}
                            disabled={isBreakActive}>
                            {lang.PunchOut}</Button> :
                        <Button
                            variant="light" size="sm"
                            onClick={() => handleModalShow(userConstants.PUNCH_IN)}>
                            {lang.PunchIn}</Button>
                }
            </div>
        </div>
    );
};
