import React from 'react';
import {connect} from "react-redux";
import Container from "react-bootstrap/Container";
import {actionGetOwnActivityLog} from "../../../../actions/user/activity-log.actions";
import { FilteredTable } from "../../shared/filteredTable";

class ActivityLog extends React.Component {


    componentDidMount() {
        this.props.actionGetOwnActivityLog();
    }

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: "Status",
                selector: 'status',
                width: "150px",
                sortable: true,
                cell: (row) => {
                    if (!row.status)
                        return "";
                    else
                        return (
                            <span 
                                style={{
                                    backgroundColor: row.status === "Success" ? "#28a745" : "#dc3545",
                                    borderRadius: "20px",
                                    color: "white",
                                    textAlign: "center",
                                    padding: "2px 8px"
                                }}
                            >
                                {row.status}
                            </span>
                        )
                }
            },
            {
                name: lang.Log,
                selector: 'message',
                sortable: true,
            }
        ];
    };

    render() {
        const {activityLogs, lang} = this.props;
        return (
            <Container fluid>
                <FilteredTable
                    title={lang.ActivityLog}
                    field={"message"}
                    columns={this.renderColumns()}
                    list={activityLogs}
                    pagination={true}
                    striped
                    keyField={'_id'}
                />
            </Container>
        );
    }
}

const mapStateToProps = ({user: {activityLogReducer}, langReducer}) => ({
    activityLogs: activityLogReducer.activityLogs,
    lang: langReducer.lang
});

export default connect(mapStateToProps, ({actionGetOwnActivityLog}))(ActivityLog);
