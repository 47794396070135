import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import DateTimePicker from 'react-datetime-picker';
import "react-datepicker/dist/react-datepicker.css";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {actionGetAttendance, actionUpdateAttendance} from "../../../../actions/admin/attendance.actions";
import {CustomDatePicker} from "../../../shared/custom-date-picker";
import {getEmployee} from "../../../../actions/admin/employee.actions";

class EditeAttendance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            punchInDateTime: '',
            punchOutDateTime: '',
            punchInComment: '',
            punchOutComment: '',
            breakTime: [],
            editComment: '',
            outlet: ''
        }

        this.handlepunchInDate = this.handlepunchInDate.bind(this);
        this.handlepunchOutDate = this.handlepunchOutDate.bind(this);
        this.handleoutlet = this.handleoutlet.bind(this);
        this.handleEditComment = this.handleEditComment.bind(this);
        this.handleBreakTime = this.handleBreakTime.bind(this);
        this.handleResumeTime = this.handleResumeTime.bind(this);
    }
    componentDidMount() {
        const {actionGetAttendance, getEmployee} = this.props;
        const {search} = this.props.location;
        const queryParams = new URLSearchParams(search);
        if (queryParams.get("attendanceId")) {
            const attendanceId = queryParams.get("attendanceId");
            actionGetAttendance(attendanceId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.attendance !== this.props.attendance) {
            const {
                editComment, 
                punchInDateTime, 
                punchInComment, 
                punchOutDateTime, 
                punchOutComment, 
                outlet, 
                breakTime
            } = this.props.attendance;
            this.setState({
                editComment: editComment,
                punchInDateTime: punchInDateTime,
                punchInComment: punchInComment ? punchInComment : "",
                punchOutDateTime: punchOutDateTime,
                punchOutComment: punchOutComment ? punchOutComment : "",
                outlet: outlet._id,
                breakTime:breakTime
            });
            this.props.getEmployee(this.props.attendance.user._id)
        }
    }
    handlepunchInDate(event) {
        this.setState({punchInDateTime: event})
    }
    handlepunchOutDate(event) {
        this.setState({punchOutDateTime: event})
    }

    handleoutlet(event) {
        this.setState({outlet: event.target.value})
    }
    handleEditComment(event) {
        this.setState({editComment: event.target.value})
    }
    handleBreakTime(event, indx) {
        debugger
        const breakTime = this.state.breakTime;
        breakTime.map((breaks, index) => {
            if (index === indx) {
                breaks.startTime = event;
            }
        })
        this.setState({breakTime});
    }
    handleResumeTime(event, indx) {
        debugger
        const breakTime = this.state.breakTime;
        breakTime.map((breaks, index) => {
            if (index === indx) {
                breaks.endTime = event;
            }
        })
        this.setState({breakTime});
    }

    handleSubmit = (attendance) => {
        try {
            attendance.editComment = this.state.editComment
            attendance.outlet = this.state.outlet
            attendance.punchInDateTime = this.state.punchInDateTime
            attendance.punchOutDateTime = this.state.punchOutDateTime
            attendance.breakTime = this.state.breakTime;
            this.props.actionUpdateAttendance(attendance)
            this.props.history.push("/admin/attendances/all");
        } catch (err) {
            console.error(err);
        }
    };
    render() {
        const {lang, attendance, employee} = this.props;
        return (
            <Container fluid>
                <Card className="mt-4 mb-5 shadow">
                    <Card.Body>
                        <Form>
                            <Card.Title>
                                {lang.EditAttendance}
                            </Card.Title>
                            <Form.Row>
                                <Form.Group as={Col} md={4}>
                                    <Form.Label>{lang.PunchedIn}</Form.Label>
                                    <DateTimePicker
                                        name="punchInDateTime"
                                        className="w-100"
                                        required={true}
                                        format="dd-MMM-yyyy HH:mm:ss"
                                        maxDate={new Date()}
                                        onChange={this.handlepunchInDate}
                                        value={this.state.punchInDateTime ? new Date(this.state.punchInDateTime) : null}
                                        customInput={<CustomDatePicker/>}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={4}>
                                    <Form.Label>{lang.PunchedOut}</Form.Label>
                                    <DateTimePicker
                                        className="w-100"
                                        required={true}
                                        name="punchOutDateTime"
                                        format="dd-MMM-yyyy HH:mm:ss"
                                        maxDate={new Date()}
                                        disabled={!this.state.punchOutDateTime}
                                        onChange={this.handlepunchOutDate}
                                        value={this.state.punchOutDateTime ? new Date(this.state.punchOutDateTime) : null}
                                        customInput={<CustomDatePicker/>}
                                    />
                                </Form.Group>
                                <Col md={4} />

                                <Form.Group as={Col} md={4}>
                                    <Form.Label>{`${lang.PunchedIn}-${lang.Comments}`}</Form.Label>
                                    <textarea
                                        className="form-control"
                                        value={this.state.punchInComment}
                                        name="punchInComments"
                                        disabled={true}
                                        rows="2"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={4}>
                                    <Form.Label>{`${lang.PunchedOut}-${lang.Comments}`}</Form.Label>
                                    <textarea
                                        className="form-control"
                                        value={this.state.punchOutComment}
                                        name="punchOutComments"
                                        disabled={true}
                                        rows="2"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Card.Title>
                                {lang.EditOutlets}
                            </Card.Title>
                            <Form.Row>
                                <Form.Group as={Col} md={8} controlId="outlets">
                                    <select className="form-control" name="outlet" value={this.state.outlet}
                                            onChange={this.handleoutlet}>
                                        {employee?.outlet?.map(outlet => (
                                            <option key={outlet._id} value={outlet._id}>{outlet.name}</option>
                                        ))}
                                    </select>
                                </Form.Group>
                            </Form.Row>
                            <Card.Title>
                                {lang.EditComments}
                            </Card.Title>
                            <Form.Row>
                                <Form.Group as={Col} md={8}>
                                     <textarea
                                         className="form-control"
                                         id="exampleFormControlTextarea1"
                                         value={this.state.editComment}
                                         name="editComment"
                                         onChange={this.handleEditComment}
                                         rows="5"/>
                                </Form.Group>
                            </Form.Row>
                            <Card.Title>
                                {lang.EditBreakTime}
                            </Card.Title>
                            {
                                this.state.breakTime?.map((breakTime, index) => (
                                    <React.Fragment key={`address-${index}`}>
                                        <Form.Label> #{index + 1}</Form.Label>
                                        <Form.Row>
                                            <Form.Group as={Col} md={4} controlId="startTime">
                                                <Form.Label>{lang.BreakTime}</Form.Label>
                                                <DateTimePicker
                                                    className="w-100"
                                                    required={true}
                                                    format="dd-MMM-yyyy HH:mm:ss"
                                                    maxDate={new Date()}
                                                    onChange={e => this.handleBreakTime(e, index)}
                                                    value={breakTime?.startTime ? new Date(breakTime?.startTime) : null}
                                                    customInput={<CustomDatePicker/>}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md={4} controlId="endTime">
                                                <Form.Label>{lang.ResumeTime}</Form.Label>
                                                <DateTimePicker
                                                    className="w-100"
                                                    required={true}
                                                    format="dd-MMM-yyyy HH:mm:ss"
                                                    maxDate={new Date()}
                                                    onChange={e => this.handleResumeTime(e, index)}
                                                    disabled={breakTime?.endTime ? false : true}
                                                    value={breakTime?.endTime ? new Date(breakTime?.endTime) : null}
                                                    customInput={<CustomDatePicker/>}
                                                />
                                            </Form.Group>
                                        </Form.Row>

                                    </React.Fragment>
                                ))
                            }
                        </Form>
                    </Card.Body>

                    <Form.Group as={Col} md={2}>
                        <Button onClick={() => this.handleSubmit(attendance)} variant="primary">
                            {lang.Save}
                        </Button>
                    </Form.Group>
                </Card>

            </Container>
        )
    }
}

const mapStateToProps = ({admin: {attendanceReducer, employeeReducer}, langReducer}) => ({
    lang: langReducer.lang,
    attendance: attendanceReducer.attendance,
    employee: employeeReducer.employee
});
export default connect(mapStateToProps, {actionGetAttendance, actionUpdateAttendance, getEmployee})(EditeAttendance);


