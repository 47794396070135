import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import * as yup from "yup";
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import {SelectCountry} from "../../../shared/select-country";
import {toFormData} from "../../../../utlis";
import {toast} from "react-toastify";
import {actionCreateNewOrganization} from "../../../../actions/organization.actions";

class CreateOrganizationModal extends React.Component {

    onSubmit = async (values, actions) => {
        try {
            const formData = toFormData(values);
            await this.props.actionCreateNewOrganization(formData);
            actions.resetForm(emptyInitialState);
            toast.success('New organization is created.');
            this.props.handleClose();
        } catch (err) {
            toast.error('Something went wrong while creating new organization.');
        }
    };

    render() {
        const {show, handleClose, lang} = this.props;
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{lang.CreateNewOrganization}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Formik
                        validationSchema={validationSchema}
                        onSubmit={this.onSubmit}
                        initialValues={emptyInitialState}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values,
                              errors,
                              setFieldValue
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label>{lang.OrganizationName}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{lang.DoingBusinessAs}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="DBA"
                                        value={values.DBA}
                                        onChange={handleChange}
                                        isInvalid={!!errors.DBA}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{lang.MissionStatement}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="missionStatement"
                                        value={values.missionStatement}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{lang.BusinessValues}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="businessValues"
                                        value={values.businessValues}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{lang.Address}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        value={values.address}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{lang.Country}</Form.Label>
                                    <SelectCountry
                                        selected={values.country}
                                        onChange={selected => setFieldValue('country', selected)}/>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{lang.Logo}</Form.Label>
                                    <input
                                        className="form-control" type="file"
                                        onChange={(event) => {
                                            setFieldValue("logo", event.currentTarget.files[0]);
                                        }}/>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{lang.LetterHead}</Form.Label>
                                    <input
                                        className="form-control" type="file"
                                        onChange={(event) => {
                                            setFieldValue("letterHead", event.currentTarget.files[0]);
                                        }}/>
                                </Form.Group>
                                <Button type="submit" size="sm">{lang.Create}</Button>
                            </Form>
                        )}
                    </Formik>

                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = ({langReducer}) => ({
    lang: langReducer.lang
});

export default connect(mapStateToProps, {actionCreateNewOrganization})(CreateOrganizationModal);

const emptyInitialState = {
    name: '',
    DBA: '',
    country: '',
    UEN: '',
    address: '',
    missionStatement: '',
    businessValues: '',
    comments: '',
    logo: null,
    letterHead: null,
    status: ''
};

const validationSchema = yup.object({
    name: yup.string().required(),

});
