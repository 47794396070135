export const userConstants = {
    ACTION_APPROVE_ATTENDANCE_RECORD: 'ACTION_APPROVE_ATTENDANCE_RECORD',
    GET_ACTIVE_ATTENDANCE_SESSION: 'GET_ACTIVE_ATTENDANCE_SESSION',
    START_NEW_ATTENDANCE_SESSION: 'START_NEW_ATTENDANCE_SESSION',
    END_ATTENDANCE_SESSION: 'END_ATTENDANCE_SESSION',
    GET_ATTENDANCE_HISTORY: 'GET_ATTENDANCE_HISTORY',
    GET_ATTENDANCE_DETAILS: 'GET_ATTENDANCE_DETAILS',
    SET_EMPLOYEE_SELECTED_ATTENDANCE: "SET_EMPLOYEE_SELECTED_ATTENDANCE",
    PUNCH_IN: 'PUNCH_IN',
    PUNCH_OUT: 'PUNCH_OUT',
    BREAK_START: 'BREAK_START',
    BREAK_END: 'BREAK_END',

    ACTION_GET_ANNOUNCEMENTS: 'ACTION_GET_ANNOUNCEMENTS',
    ACTION_GET_ONE_ANNOUNCEMENT: 'ACTION_GET_ONE_ANNOUNCEMENT',
    ACTION_CREATE_ANNOUNCEMENT_ACK: 'ACTION_CREATE_ANNOUNCEMENT_ACK',
    ACTION_ACK_EMPLOYEE_ATTENDANCE: "ACTION_ACK_EMPLOYEE_ATTENDANCE",

    ACTION_GET_ACTIVITY_LOG: 'ACTION_GET_ACTIVITY_LOG',
    ACTION_GET_ORGANIZATIONS: 'ACTION_GET_ORGANIZATIONS',
    SET_SELECTED_ORGANIZATION: 'SET_SELECTED_ORGANIZATION',

    CREATE_ATTENDANCE_CHANGE_REQUEST: 'CREATE_ATTENDANCE_CHANGE_REQUEST',
    CREATE_BREAKTIME_CHANGE_REQUEST: 'CREATE_BREAKTIME_CHANGE_REQUEST',
    GET_BREAKTIME_CHANGE_REQUEST: 'GET_BREAKTIME_CHANGE_REQUEST',
    UPDATE_BREAKTIME_ATTENDANCE_CHANGE_REQUEST: 'UPDATE_BREAKTIME_ATTENDANCE_CHANGE_REQUEST',
    UPDATE_ATTENDANCE_CHANGE_REQUEST: 'UPDATE_ATTENDANCE_CHANGE_REQUEST',
    UPDATE_ATTENDANCE_COMMENT: "UPDATE_ATTENDANCE_COMMENT",

    ACTION_GET_USER_OUTLETS: "ACTION_GET_USER_OUTLETS",

};
