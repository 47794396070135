import {authenticationConstants} from "../constants";
import axios from './axiosConfig';
import {trackPromise} from "react-promise-tracker";
import {toast} from "react-toastify";

export const loginAction = (data) => async dispatch => {

    try {
        const response = await trackPromise(axios.post(`auth/signin`, data));

        const {data: {token, user}} = response.data;
        const {_id, firstName, lastName, email, contact, userName, org, activeOrg, isSuperAdmin} = user;

        dispatch({
            type: authenticationConstants.LOGGED_IN,
            payload: {
                _id,
                firstName,
                lastName,
                email,
                contact,
                userName,
                isSuperAdmin,
                token,
                isOwner: org.some(o => o.isOwner),
                activeOrg,
                attachedOrgs: org
            }
        });
        return response;
    } catch (err) {
        const {response} = err;
        if (response && response.status === 400) {
            toast.error(response.data.message);
        }
        throw err;
    }
};

export const logoutAction = () => dispatch => {
    dispatch({type: authenticationConstants.LOG_OUT});
};
