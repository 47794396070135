import AttendanceVerificationRecords from "../attendance-verification-records";
import AttendanceAcknowledgementRecords from "../attendance-ack-records";
import React from "react";
import lang from "../../../../../language/en.json"

export const RejectedAttendances = (props) => {
    return (
        <>
            <h4 className="mt-3">Attendance Records</h4>
            <AttendanceVerificationRecords status="rejected" title={lang.VerifiedAttendanceRecords}/>
            <AttendanceAcknowledgementRecords status="rejected" title={lang.FlaggedAcknowledgements}/>
        </>
    );
};
