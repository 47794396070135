import React from 'react';
import styles from './index.module.css';

export const ModalImage = (props) => {

    const {show, handleClose, imageUrl, caption} = props;

    return (
        <div id="myModal" className={styles.modal} style={{display: show ? 'block' : 'none'}}>
            <span className={styles.close} onClick={handleClose}>&times;</span>
            <img className={styles.modalContent} id="img01" src={imageUrl} alt=""/>
            <div className={styles.caption}>{caption}</div>
        </div>
    )
};
