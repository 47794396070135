import React from 'react';
import {Card} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {calculateTimeDiff, convertDateTimeToCustomFormat} from "../../../../../../utlis";
import {userConstants} from "../../../../../../constants";
import Badge from "react-bootstrap/Badge";
import {connect} from "react-redux";
import {
    acknowledgeAttendance,
    acknowledgeCompleteAttendance,
    approveAttendanceRecord
} from "../../../../../../actions/user/attendance.actions";
import {AttendanceVerification} from "../../attendance-verification";
import {actionGetAttendanceVerificationOptions} from "../../../../../../actions/user/attendance-verification-options.actions";
import {AttendanceAcknowledgment} from "../../../../shared/attendance/attendance-acknowledgement";
import {setSelectedAttendance} from "../../../../../../actions/user/attendance.actions";

class VerifiedAttendanceRecords extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showAttendanceVerificationModal: false,
            showAttendanceAckModal: false,
            selectedAttendance: {},
        }
    }

    componentDidMount() {
        this.props.actionGetAttendanceVerificationOptions();
    }

    renderColumns = () => {
        const {lang} = this.props;
        const colums = [
            {
                name: lang.Name,
                selector: 'user.firstName',
                sortable: true,
                cell: row => row.user.firstName
            },
            {
                name: lang.Outlet,
                selector: 'outlet.name',
                sortable: true,
                cell: row => row.outlet?.name
            },
            {
                name: lang.PunchInTime,
                selector: 'punchInDateTime',
                sortable: true,
                cell: row => {
                    if (!row.ifLate) {
                        return convertDateTimeToCustomFormat(row.punchInDateTime)
                    } else {
                        return convertDateTimeToCustomFormat(row.changeRequest?.adjustedTime.punchInDateTime)
                    }
                }
            },
            {
                name: lang.PunchOutTime,
                selector: 'punchOutDateTime',
                sortable: true,
                cell: row => {
                    if (row.ifLate) {
                        return row.punchOutDateTime ? convertDateTimeToCustomFormat(row.changeRequest?.adjustedTime.punchOutDateTime) : '-----'

                    } else {
                        return row.punchOutDateTime ? convertDateTimeToCustomFormat(row.punchOutDateTime) : '-----'
                    }
                }
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>
                        {row.status === userConstants.PUNCH_IN && <Badge variant="success">{lang.PunchedIn}</Badge>}
                        {row.status === userConstants.PUNCH_OUT && <Badge variant="light">{lang.PunchedOut}</Badge>}
                        {row.status === userConstants.BREAK_START && <Badge variant="warning">{lang.OnBreak}</Badge>}
                    </h6>
                )
            },
            {
                name: lang.TotalHours,
                sortable: true,
                cell: row => {
                    return this.calculateTotalTime(row);
                }
            }
           
        ];

        if (this.props.status !== 'verified') {
            colums.push({
                name: lang.Actions,
                button: true,
                cell: row => (
                    <>
                        <span className="btn btn-sm btn-info"
                              onClick={() => {
                                  this.props.setSelectedAttendance(row);
                                  this.setState({showAttendanceAckModal: true});
                              }}>
                           <i className="fas fa-camera"/>
                        </span>
                    </>)
            })
        }
        return colums;
    };

    calculateTotalTime = (record) => {
        const startTime = record.ifLate ? record.changeRequest?.adjustedTime.punchInDateTime : record.punchInDateTime;
        const endTime = record.punchOutDateTime ? record.ifLate ? record.changeRequest?.adjustedTime.punchOutDateTime : record.punchOutDateTime : (new Date()).toISOString();
        return calculateTimeDiff(endTime, startTime);
    };

    getAttendanceRecords = () => {
        if (this.props.status === 'pending') {
            return this.props.attendanceHistory?.filter(attendance => !attendance.isVerified && attendance.status === userConstants.PUNCH_OUT);
        } else {
            return this.props.attendanceHistory?.filter(attendance => attendance.isVerified);
        }
    };


    render() {
        const customStyles = {
            header: {
              style: {
                minHeight: '50px',
                width: 'auto'
              },
            },
            headRow: {
              style: {
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
               
              },
            },
            headCells: {
              style: {
                '&:not(:last-of-type)': {
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  
                },
              },
            },
            cells: {
              style: {
                
                '&:not(:last-of-type)': {
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                },
              },
            },
          };
        const {showAttendanceAckModal, showAttendanceVerificationModal} = this.state;
        const {lang, title, approveAttendanceRecord, selectedAttendance} = this.props;

        return (
            <Card>
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <DataTable
                    customStyles = {customStyles}
                        columns={this.renderColumns()}
                        data={this.getAttendanceRecords()}
                        pagination={true}
                        highlightOnHover
                        pointerOnHover
                        onRowClicked={(row) => {
                            this.props.setSelectedAttendance(row);
                            this.setState({
                                showAttendanceVerificationModal: true,
                            })
                        }}
                    />
                    <AttendanceAcknowledgment
                        attendance={selectedAttendance}
                        showModal={showAttendanceAckModal}
                        handleModalClose={() => this.setState({showAttendanceAckModal: false})}
                        canAcknowledge={true}
                        handleSubmitAcknowledgement={this.props.acknowledgeAttendance}
                        handleBulkAcknowledgement={this.props.acknowledgeCompleteAttendance}
                        lang={lang}/>

                    {/*<AttendanceDetailModal*/}
                    {/*    showModal={showAttendanceAckModal}*/}
                    {/*    handleModalClose={() => this.setState({showAttendanceAckModal: false})}*/}
                    {/*    attendanceId={selectedAttendance._id} lang={lang}*/}
                    {/*    handleAcknowledgement={acknowledgeAttendance}*/}
                    {/*    handleCompleteAcknowledgement={(status) => acknowledgeCompleteAttendance(selectedAttendance._id, {status})}*/}
                    {/*/>*/}

                    <AttendanceVerification
                        show={showAttendanceVerificationModal}
                        handleClose={() => this.setState({showAttendanceVerificationModal: false})}
                        attendanceId={selectedAttendance._id} lang={lang}
                        handleVerifyAttendance={approveAttendanceRecord}
                        openAckModal={() =>
                            this.setState({showAttendanceVerificationModal: false, showAttendanceAckModal: true})
                        }
                    />

                </Card.Body>
            </Card>
        );
    }
}

const mapStateToProps = ({user: {attendanceReducer, ARVO_Reducer}, langReducer}) => ({
    attendanceHistory: attendanceReducer.attendanceHistory,
    selectedAttendance: attendanceReducer.selectedAttendance,
    ARVO_Fields: ARVO_Reducer.ARVO_Fields,
    lang: langReducer.lang
});

export default connect(mapStateToProps, ({
    acknowledgeAttendance,
    acknowledgeCompleteAttendance,
    approveAttendanceRecord,
    actionGetAttendanceVerificationOptions,
    setSelectedAttendance
}))(VerifiedAttendanceRecords);
