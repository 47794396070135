import React from 'react';
import {connect} from "react-redux";
import {actionGetAnnouncement, actionCreateAnnouncementAck} from "../../../../../actions/user/announcement.actions";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {WriteAcknowledgementModal} from "./write-acknowledgement-modal";
import {API_URL} from "../../../../../config";


class ViewAnnouncement extends React.Component {

    constructor() {
        super();
        this.state = {
            showModal: false,
            ackSubmitted: false
        }
    }

    componentDidMount() {
        const {search} = this.props.location;
        const queryParams = new URLSearchParams(search);
        if (queryParams.get('id')) {
            const announcementId = queryParams.get('id');
            this.props.actionGetAnnouncement(announcementId);
        }
    }

    handleCloseModal = () => {
        this.setState({showModal: false})
    };

    submitAcknowledgement = async (formData) => {
        const {loggedInUser, announcement} = this.props;
        formData.append('user', loggedInUser._id);
        await this.props.actionCreateAnnouncementAck(formData, announcement._id);
        this.setState({showModal: false, ackSubmitted: true});
    };

    getAcknowledgement = () => {
        const {loggedInUser, announcement} = this.props;
        //console.log(announcement)
        if (announcement.acknowledge) {
            const found = announcement.acknowledge.find(ack => ack.user === loggedInUser._id);
            if (found) {
                return (
                    <>
                        <img src={`${found.image}`} style={{height: '150px'}}
                             alt="Acknowledgement"/><br/>
                        <p>{found.acknowledgement}</p>
                    </>
                )
            }
        }
        return <p>No Acknowledgement Available.</p>
    };

    render() {
        const {announcement, lang} = this.props;
        return (
            <Container fluid>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>{lang.ViewAnnouncement}</Card.Title>
                        <Row className="form-group mt-4">
                            <Col md={3} className="font-weight-bold">{lang.Title}</Col>
                            <Col md={9}>{announcement.title}</Col>
                        </Row>
                        <Row className="form-group">
                            <Col md={3} className="font-weight-bold">{lang.Description}</Col>
                            <Col md={9}>
                                <div dangerouslySetInnerHTML={{__html: announcement.description}}/>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        {!this.state.ackSubmitted &&
                        <Button variant="success" size={'sm'} onClick={() => this.setState({showModal: true})}>
                            {lang.WriteAcknowledgement}</Button>
                        }
                    </Card.Footer>
                </Card>
                <WriteAcknowledgementModal
                    showModal={this.state.showModal} handleCloseModal={this.handleCloseModal}
                    submitAcknowledgement={this.submitAcknowledgement} lang={lang}/>
                <Card className="mt-3 mb-5">
                    <Card.Body>
                        <Card.Title>{lang.YourAcknowledgement}</Card.Title>
                        <span className="mt-3"/>
                        {this.getAcknowledgement()}
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = ({user: {announcementReducer}, authentication, langReducer}) => ({
    announcement: announcementReducer.announcement,
    loggedInUser: authentication.loggedInUser,
    lang: langReducer.lang
});

export default connect(mapStateToProps, ({actionGetAnnouncement, actionCreateAnnouncementAck}))(ViewAnnouncement);
