import { adminConstants } from "../../constants";
import axios from "../axiosConfig";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";

export const getEmployees = () => async (dispatch) => {
  const response = await trackPromise(axios.get(`admin/employees/list`));
  dispatch({
    type: adminConstants.GET_EMPLOYEES,
    payload: response.data.data,
  });
};

export const getEmployee = (employeeId) => async (dispatch) => {
  const response = await trackPromise(
    axios.get(`admin/employees/list/${employeeId}`)
  );
  dispatch({
    type: adminConstants.GET_EMPLOYEE,
    payload: response.data.data,
  });
};

export const addEmployee = (employee) => async (dispatch) => {

  try {
    debugger
    const response = await trackPromise(
      axios.post(`admin/employees/create`, employee)
    );
    toast.success("New user is created.");
    dispatch({
      type: adminConstants.ADD_EMPLOYEE,
      payload: response.data.data,
    });
  } catch (error) {
    const { response } = error;

    if (response.status === 409) {
      toast.error(response.data.message);
    } else {
      toast.error("Error occurred while creating new user");
    }
    throw response;
  }
};

export const updateEmployee = (employee) => async (dispatch) => {
  try {
    const response = await trackPromise(
      axios.put(`admin/employees/edit/${employee._id}`, employee)
    );
    toast.success("Employee is updated.");
    dispatch({
      type: adminConstants.UPDATE_EMPLOYEE,
      payload: response.data.data,
    });
  } catch (error) {
    toast.error("Error occurred while saving user");
    throw error;
  }
};

export const updateEmployeePassword = async (employeeId, password) => {
  try {
    const response = await trackPromise(
      axios.put(`admin/employees/changePassword/${employeeId}`, { password })
    );
    toast.success("Employee password is updated.");
    // dispatch({
    //   type: adminConstants.UPDATE_EMPLOYEE,
    //   payload: response.data.data,
    // });
    return response;
  } catch (error) {
    toast.error("Error occurred while saving user");
    throw error;
  }
};
