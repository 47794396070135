import React, {useEffect, useState} from 'react';
import Select from "react-select";

export const SelectGroups = (props) => {

    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    const {groups = [], values = [], handleSelectChange} = props;

    useEffect(() => {
        const options = groups.map(group => ({
            value: group._id,
            label: group.name
        }));

        setSelectOptions(options);

        const result = groups.filter(group => values.some(groupId => groupId === group._id))
            .map(group => ({
                value: group._id,
                label: group.name
            }));

        setSelectedValues(result);

    }, [groups, values]);

    const handleChange = (selected) => {
        const groupIds = selected ? selected.map(group => group.value) : [];
        handleSelectChange(groupIds);
    };

    return (
        <Select
            options={selectOptions}
            value={selectedValues}
            onChange={handleChange}
            isSearchable
            isMulti
        />
    )
};
