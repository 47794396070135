import React from 'react';
import {Card, Col, Container, Form, Row, InputGroup, FormControl, Button} from "react-bootstrap";
import './Login.css';
import {connect} from "react-redux";
import {loginAction} from '../../actions/authentication.actions';
import {actionGetOrganizations} from "../../actions/organization.actions";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            identity: '',
            password: '',
        }
    }


    handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        this.setState({validated: true});
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }
        const data = {...this.state};
        delete data.validated;

        this.props.loginAction(data).then(() => {
            this.navigateToDashboard();
        });
    };

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    navigateToDashboard = (response) => {
        const loggedInUser = JSON.parse(localStorage.getItem('user'));
        if (!loggedInUser.activeOrg) {
            this.props.history.push('/select-organization');
        } else {
            this.props.history.push('/admin/dashboard');
            this.props.history.push('/user/dashboard');
        }
    };

    render() {

        const {validated, identity, password} = this.state;
        const {lang} = this.props;

        return (
            <Container>
                <Row>
                    <Col md={{span: 6, offset: 3}} className="align-self-center">
                        <h1 className="text-center font-weight-bold mt-5">Attendance</h1>
                        <Card>
                            <small className="text-center mt-5 mb-3">{lang.SignInToStartYourSession}</small>
                            <Form className="mx-5" noValidate validated={validated} onSubmit={this.handleSubmit}>
                                <Form.Group>
                                    <InputGroup>
                                        <FormControl
                                            value={identity}
                                            onChange={this.handleChange}
                                            name="identity"
                                            placeholder={lang.EmailOrUsername}
                                            aria-label="Username or Email"
                                            aria-describedby="username-addon"
                                            required
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text id="username-addon">
                                                <i className="fas fa-user"/>
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <InputGroup>
                                        <FormControl
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={this.handleChange}
                                            placeholder={lang.Password}
                                            aria-label="Password"
                                            aria-describedby="password-addon"
                                            required
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text id="password-addon">
                                                <i className="fas fa-lock"/>
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="mb-5">
                                    <Button variant="primary" block type="submit">{lang.Login}</Button>
                                    <small className="text-info">{lang.ForgotPassword}?</small>
                                </Form.Group>

                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({langReducer}) => ({
    lang: langReducer.lang
});

export default connect(mapStateToProps, {loginAction, actionGetOrganizations})(Login);
