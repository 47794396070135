import {Route} from "react-router-dom";
import UserDashboard from "../../saas-user/user/dashboard/UserDashboard";
import Attendance from "../../saas-user/user/attendance";
import UserAnnouncements from "../../saas-user/user/announcements";
import ViewAnnouncement from "../../saas-user/user/announcements/view-announcement";
import React, {useEffect} from "react";
import ActivityLog from "../../saas-user/user/activity-log";
import {useSelector} from "react-redux";
import swal from "sweetalert";
import ViewAttendanceDetails from "../../saas-user/user/attendance/view-attendance-details";

export const UserRoutes = (props) => {

    let {match, location} = props;
    const {unAcknowledgedCount} = useSelector(state => state.user.announcementReducer);

    useEffect(() => {
        if (!location.pathname.includes('announcements') && unAcknowledgedCount > 0) {
            showUnAcknowledgedAnnouncementsNotification();
        }
    });

    const showUnAcknowledgedAnnouncementsNotification = () => {
        swal({
            text: `There are ${unAcknowledgedCount} unacknowledged announcements.`,
            icon: 'info',
            buttons: {
                cancel: true,
                viewAnnouncements: {
                    text: 'Go to Announcements',
                    value: 'viewAnnouncements'
                }
            }
        }).then(value => {
            if (value === 'viewAnnouncements')
                props.history.push('/user/announcements');
        })
    };
    return (
        <>
            <Route path={`${match.path}/dashboard`} component={UserDashboard}/>
            <Route exact path={`${match.path}/attendance/:for`} component={Attendance}/>
            <Route exact path={`${match.path}/attendance/view/:attendanceId`} component={ViewAttendanceDetails}/>
            <Route exact path={`${match.path}/announcements`} component={UserAnnouncements}/>
            <Route exact path={`${match.path}/announcements/view`} component={ViewAnnouncement}/>
            <Route exact path={`${match.path}/activity-logs`} component={ActivityLog}/>
        </>
    )
};
