import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {convertDateTimeToCustomFormat, calculateTimeDiff, convertDateToCustomFormat} from "../../../../../utlis";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {API_URL} from "../../../../../config";
import {Accordion, Card} from "react-bootstrap";
import {useSelector} from "react-redux";
import {ModalImage} from "../../../../shared/modal-image";
import {AttendanceImage} from "./attendance-image";
import styles from "./index.module.css";

export const AttendanceDetailModal = (props) => {

    const {showModal, handleModalClose, attendanceId, lang} = props;
    const [attendance, setAttendance] = useState({});
    const [user, setUser] = useState({});
    const [isAttendancePartiallyAck, setIsAttendancePartiallyCompleteAck] = useState(false);
    const [showModalImage, setShowModalImage] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState('');
    const [modalImageCaption, setModalImageCaption] = useState('');
    const [canAcknowledge, setCanAcknowledge] = useState(false);

    const {attendanceHistory} = useSelector(state => state.user.attendanceReducer);
    const {selectedOrganization} = useSelector(state => state.organizationReducer);

    useEffect(() => {
        if (attendanceHistory?.length > 0) {
            const found = attendanceHistory.find(at => at._id === attendanceId);
            if (found) {
                setAttendance(found);
                setUser(found.user);

                const isSupervisor = selectedOrganization.otSupervisorOn.some(userId => userId === found.user._id);
                setCanAcknowledge(isSupervisor);

                if (found.punchInAck || found.punchOutAck) {
                    setIsAttendancePartiallyCompleteAck(true);
                } else {
                    const anyBreakAck = found.breakTime.some((breakTime) => breakTime.startAck || breakTime.endAck);
                    setIsAttendancePartiallyCompleteAck(anyBreakAck);
                }
            }
        }

    }, [attendanceHistory, attendanceId, selectedOrganization.otSupervisorOn]);

    const getPunchInDateTime = () => {
        if (attendance?.ifLate) {
            return convertDateTimeToCustomFormat(attendance?.changeRequest.adjustedTime.punchInDateTime);
        } else {
            return convertDateTimeToCustomFormat(attendance?.punchInDateTime);
        }
    };

    const getPunchOutDateTime = () => {
        if (attendance?.ifLate) {
            return convertDateTimeToCustomFormat(attendance?.changeRequest?.adjustedTime.punchOutDateTime);
        } else {
            return convertDateTimeToCustomFormat(attendance?.punchOutDateTime);
        }
    };

    const handleAttendanceAcknowledge = (statusFor, approved) => {
        props.handleAcknowledgement(attendance._id, {[statusFor]: approved});
    };

    const handleBreakImageAcknowledge = (breakId, statusFor, approved) => {
        const data = {id: breakId, [statusFor]: approved};
        props.handleAcknowledgement(attendance._id, {breaks: data});
    };

    const onClickImage = (imgUrl, caption) => {
        setModalImageUrl(imgUrl);
        setModalImageCaption(caption);
        setShowModalImage(true);
    };

    return (
        <Modal show={showModal} onHide={handleModalClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {lang.AttendanceDetails}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalImage show={showModalImage} imageUrl={modalImageUrl}
                            caption={modalImageCaption} handleClose={() => setShowModalImage(false)}/>
                <Row className="form-group">
                    <Col md={4}>
                        <label>Employee Username</label>
                        <input className="form-control" value={`${user?.userName}`} readOnly/>
                    </Col>
                    <Col md={4}>
                        <label>Employee Name</label>
                        <input className="form-control" value={`${user?.firstName} ${user?.lastName}`} readOnly/>
                    </Col>
                    <Col md={4}>
                        <label>Attendance Date</label>
                        <input className="form-control"
                               value={convertDateToCustomFormat(attendance.createdAt)} readOnly/>
                    </Col>
                </Row>

                <Row className="form-group">
                    <Col md={6}>
                        <label>PunchIn Time</label>
                        <input className="form-control"
                               value={getPunchInDateTime()}
                               readOnly/>

                        <div className="position-relative">
                            <img src={`${attendance.punchInImage}`}
                                 className="img-fluid img-thumbnail mt-2"
                                 onClick={() => onClickImage(`${attendance.punchInImage}`)}
                                 alt=''/>
                        </div>

                    </Col>
                    <Col md={6}>
                        <label>PunchOut Time</label>
                        <input className="form-control" readOnly
                               value={attendance?.punchOutDateTime ? getPunchOutDateTime() : '----'}/>

                        <div className="position-relative">
                            <img src={`${attendance.punchOutImage}`}
                                 className="img-fluid img-thumbnail mt-2"
                                 onClick={() => onClickImage(`${attendance.punchOutImage}`)}
                                 alt=''/>
                        </div>
                    </Col>
                </Row>

                <div className="d-flex px-4 mt-5">
                    <span style={{width: "20px"}}>#</span>
                    <span className="font-weight-bold" style={{width: "33%"}}>{lang.BreakTime}</span>
                    <span className="font-weight-bold" style={{width: "33%"}}>{lang.ResumeTime}</span>
                    <span className="font-weight-bold" style={{width: "33%"}}>{lang.TotalTime}</span>
                </div>

                {attendance && attendance.breakTime?.map((breakTime, index) => (
                    <Accordion key={breakTime._id}>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0" className="cursor-pointer">
                                <div className="d-flex">
                                    <span style={{width: "20px"}}>{index + 1}</span>
                                    <span style={{width: "33%"}}>
                                        {convertDateTimeToCustomFormat(breakTime.startTime)}
                                    </span>
                                    <span style={{width: "33%"}}>
                                        {breakTime.endTime ? convertDateTimeToCustomFormat(breakTime.endTime) : '----'}
                                    </span>
                                    <span style={{width: "33%"}}>
                                        {breakTime.endTime ? calculateTimeDiff(breakTime.endTime, breakTime.startTime) : '----'}
                                    </span>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Row className="py-3">
                                        <Col md={{span: 6}}>
                                            <span>Break #{index + 1} image</span>
                                        </Col>
                                        <Col md={1}/>
                                        <Col md={4}>
                                            <span>Resume #{index + 1} image</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{span: 6}}>

                                            <div className="position-relative">
                                                <img src={breakTime.startImage}
                                                     className="img-fluid img-thumbnail mt-2"
                                                     onClick={() => onClickImage(breakTime.startImage, `Break #${index + 1} image`)}
                                                     alt=''/>
                                            </div>

                                        </Col>
                                        <Col md={6}>

                                            <div className="position-relative">
                                                <img src={breakTime.endImage}
                                                     className="img-fluid img-thumbnail mt-2"
                                                     onClick={() => onClickImage(breakTime.endImage, `Resume #${index + 1} image`)}
                                                     alt=''/>
                                            </div>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleModalClose}>
                    {lang.Close}
                </Button>
            </Modal.Footer>
        </Modal>
    )
};
