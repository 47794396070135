import {combineReducers} from "redux";
import admin from './admin';
import user from './user';
import {authentication} from './authentication.reducer';
import langReducer from './language.reducer';
import organizationReducer from './organization.reducer';

export default combineReducers({
    admin,
    user,
    authentication,
    langReducer,
    organizationReducer
});
