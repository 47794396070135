import {adminConstants} from "../../constants";
import axios from '../axiosConfig';
import {toast} from 'react-toastify';
import {trackPromise} from 'react-promise-tracker';

export const getOutlets = () => async dispatch => {
    const response = await trackPromise(axios.get(`outlets/list`));
    dispatch({
        type: adminConstants.GET_OUTLETS,
        payload: response.data.data
    });
};

export const getOutlet = (groupId) => async dispatch => {
    const response = await trackPromise(axios.get(`outlets/list/${groupId}`));
    dispatch({
        type: adminConstants.GET_OUTLET,
        payload: response.data.data
    });
};

export const addOutlet = (outlet) => async dispatch => {
    try {
        const response = await trackPromise(axios.post(`outlets/new`, outlet));
        toast.success('New outlet is created.');
        dispatch({
            type: adminConstants.ADD_OUTLET,
            payload: response.data.data
        });
    } catch (error) {
        toast.error('Error occurred while creating outlet');
        throw error;
    }
};

// export const deleteGroup = (groupId) => async dispatch => {
//     try {
//         const response = await axios.delete(`outlets/create`);
//
//     } catch (error) {
//
//     }
// };

export const updateOutlet = (outlet) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`outlets/edit/${outlet._id}`, outlet));
        toast.success('Outlet is updated.');

        dispatch({
            type: adminConstants.UPDATE_OUTLET,
            payload: response.data.data
        });
    } catch (error) {
        toast.error('Error occurred while saving outlet');
    }
};
