import {commonConstants} from "../constants";

const INITIAL_STATE = {
    lang: {},
    locale: '',
    emoji: ''
};

export default function (state = INITIAL_STATE, action) {
    if (action.type === commonConstants.ACTION_GET_LANG) {
        localStorage.setItem('lang', JSON.stringify(action.payload.lang));
        localStorage.setItem('locale', action.payload.locale);
        localStorage.setItem('emoji', action.payload.emoji);
        return {
            ...state,
            lang: action.payload.lang,
            locale: action.payload.locale,
            emoji: action.payload.emoji
        };
    } else {
        return {...state};
    }
};
