import axios from "./axiosConfig";
import {adminConstants, userConstants} from "../constants";

export const actionGetOrganizations = () => async dispatch => {
    const response = await axios.get('/organization/list');
    dispatch({type: userConstants.ACTION_GET_ORGANIZATIONS, payload: response.data.data})
};

export const setSelectedOrganization = (organization) => dispatch => {
    dispatch({type: userConstants.SET_SELECTED_ORGANIZATION, payload: organization})
};

export const actionCreateNewOrganization = (data) => async dispatch => {
    try {
        const response = await axios.post('/admin/organization/create', data);
        dispatch({type: adminConstants.CREATE_ORGANIZATION, payload: response.data.data});
        return response;
    } catch (err) {
        throw err;
    }
};

export const actionUpdateOrganization = data => async dispatch => {
    try {
        const response = await axios.put('/admin/organization/edit/' + data._id, data);
        dispatch({type: adminConstants.ACTION_UPDATE_ORGANIZATION, payload: response.data.data});
        return response;
    } catch (e) {

    }
};
