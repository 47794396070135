import {adminConstants, userConstants} from "../../constants";
import axios from '../axiosConfig';
import {trackPromise} from "react-promise-tracker";
import {toast} from "react-toastify";

export const getActiveSessionAttendance = (userId) => async dispatch => {
    const response = await trackPromise(axios.get(`attendance/session/${userId}`,));
    dispatch({type: userConstants.GET_ACTIVE_ATTENDANCE_SESSION, payload: response.data.data});
    return response;
};


export const startNewAttendanceSession = (data) => async dispatch => {
    try {
        const response = await trackPromise(axios.post(`attendance/new`, data));

        dispatch({type: userConstants.START_NEW_ATTENDANCE_SESSION, payload: response.data.data});
        return response;

    } catch (err) {
        toast.error("Error: cannot punch-in");
    }
};

export const endAttendanceSession = (attendanceId, data) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`attendance/edit/${attendanceId}`, data));
        dispatch({type: userConstants.END_ATTENDANCE_SESSION, payload: response.data.data});
        return response;
    } catch (err) {
        toast.error("Error: cannot punch-out");
    }
};

export const startBreakTime = (attendanceId, data) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`attendance/breakTime/${attendanceId}`, data));
        dispatch({type: userConstants.BREAK_START, payload: response.data.data});
        return response;

    } catch (err) {
        toast.error("Error: cannot start break");
    }
};


export const endBreakTimeAction = (attendanceId, data) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`attendance/breakTime/${attendanceId}`, data));
        dispatch({type: userConstants.BREAK_END, payload: response.data.data});
        return response;

    } catch (err) {
        toast.error("Error: cannot end break");
    }
};

export const getAttendanceHistory = (params) => async dispatch => {
    try {
        const response = await trackPromise(axios.get(`employees/attendance/list`, {params}));
        dispatch({type: userConstants.GET_ATTENDANCE_HISTORY, payload: response.data.data});
        return response;
    } catch (err) {

    }
};

export const getAttendanceDetails = (attendanceId) => async dispatch => {
    try {
        const response = await trackPromise(axios.get(`attendance/${attendanceId}`));
        dispatch({type: userConstants.GET_ATTENDANCE_DETAILS, payload: response.data.data});
        return response;
    } catch (e) {

    }
};

export const createAttendanceChangeRequest = (data) => async dispatch => {
    try {
        const response = await trackPromise(axios.post('employees/attendance/request', data));
        dispatch({type: userConstants.CREATE_ATTENDANCE_CHANGE_REQUEST, payload: response.data.data});
        return response;
    } catch (e) {

    }
};
export const createAttendanceBtChangeRequest = (data) => async dispatch => {
    try {
        const response = await trackPromise(axios.post('employees/breaktime/request', data));
        dispatch({type: userConstants.CREATE_BREAKTIME_CHANGE_REQUEST, payload: response.data.data});
        return response;
    } catch (e) {

    }
};
export const getBtAttendanceDetails = (userId) => async dispatch => {
    try {
        const response = await trackPromise(axios.get(`employees/breaktime/request/list/${userId}`));
        dispatch({type: userConstants.GET_BREAKTIME_CHANGE_REQUEST, payload: response.data.data});
        return response;
    } catch (e) {

    }
};

export const updateAttendanceChangeRequest = (data, requestId) => async dispatch => {
    try {
        const response = await trackPromise(axios.put('employees/attendance/request/edit?id=' + requestId, data));
        dispatch({type: userConstants.UPDATE_ATTENDANCE_CHANGE_REQUEST, payload: response.data.data});
        return response;

    } catch (e) {

    }
};
export const updateBtAttendanceChangeRequest = (data, requestId) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`employees/breaktime/request/edit/${requestId}`, data));
        dispatch({type: userConstants.UPDATE_BREAKTIME_ATTENDANCE_CHANGE_REQUEST, payload: response.data.data});
        return response;

    } catch (e) {

    }
};

export const updateChangeRequestComment = (data) => async dispatch => {
    try {
        const response = await trackPromise(axios.put('attendance/comment/edit', data));
        dispatch({type: userConstants.UPDATE_ATTENDANCE_COMMENT, payload: response.data.data});
        return response;
    } catch (e) {

    }
};

export const acknowledgeAttendance = (attendanceId, data) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`employees/attendance/ack/${attendanceId}`, data));
        dispatch({type: userConstants.ACTION_ACK_EMPLOYEE_ATTENDANCE, payload: response.data.data});
        return response;
    } catch (e) {

    }
};

export const acknowledgeCompleteAttendance = (attendanceId, data) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`employees/attendance/bulkAck/${attendanceId}`, data));
        dispatch({type: userConstants.ACTION_ACK_EMPLOYEE_ATTENDANCE, payload: response.data.data});
        return response;
    } catch (e) {

    }
};

export const approveAttendanceRecord = (attendanceId, data) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`attendance/verify/${attendanceId}`, data));
        dispatch({type: userConstants.ACTION_APPROVE_ATTENDANCE_RECORD, payload: response.data.data});
        return response;
    } catch (e) {

    }
};

export const setSelectedAttendance = (attendance) => dispatch => {
    dispatch({type: userConstants.SET_EMPLOYEE_SELECTED_ATTENDANCE, payload: attendance})
};
