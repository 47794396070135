import {Route, Switch} from "react-router-dom";
import React from "react";
import Dashboard from "../../saas-user/admin/dashboard/Dashboard";
import GroupsList from "../../saas-user/admin/group-management/GroupsList";
import GroupDefinition from "../../saas-user/admin/group-management/GroupDefinition";
import EmployeesList from "../../saas-user/admin/employee-management/EmployeesList";
import EmployeeDefinition from "../../saas-user/admin/employee-management/EmployeeDefinition";
import AdminAnnouncement from "../../saas-user/admin/annoucements";
import AnnouncementDefinition from "../../saas-user/admin/annoucements/AnnouncementDefinition";
import ContentManagement from "../../saas-user/admin/content-management";
import ContentDefinition from "../../saas-user/admin/content-management/ContentDefinition";
import ViewContent from "../../saas-user/admin/content-management/ViewContent";
import ViewAnnouncement from "../../saas-user/admin/annoucements/view-announement";
import OutletsList from "../../saas-user/admin/outlet-management/OutletsList";
import OutletDefinition from "../../saas-user/admin/outlet-management/OutletDefinition";
import AttendanceManagement from "../../saas-user/admin/attendance-management";
import Settings from "../../saas-user/admin/settings";
import AttendanceSettings from "../../saas-user/admin/attendance-management/attendance-settings";
import AttendancePendingAckApprovals
    from "../../saas-user/admin/attendance-management/attendance-pending-ack-approvals";
import {PendingAttendances} from "../../saas-user/admin/attendance-management/pending";
import {RejectedAttendances} from "../../saas-user/admin/attendance-management/rejected";
import AccountSettings from "../../saas-user/shared/account-settings";
import CreateLeaves from "../../saas-user/admin/leave-management/create-leaves";
import EditeAttendance from "../../saas-user/admin/attendance-management/EditeAttendance";
import ActivityLog from "../../saas-user/admin/activity-log";


export const AdminRoutes = (props) => {
    let {match} = props;

    return (

        <>
            <Route path={`${match.path}/dashboard`} component={Dashboard}/>
            <Route exact path={`${match.path}/groups`} component={GroupsList}/>
            <Route path={`${match.path}/groups/:action`} component={GroupDefinition}/>
            <Route exact path={`${match.path}/outlets`} component={OutletsList}/>
            <Route path={`${match.path}/outlets/:action`} component={OutletDefinition}/>
            <Route exact path={`${match.path}/employees`} component={EmployeesList}/>
            <Route path={`${match.path}/employees/:action`} component={EmployeeDefinition}/>
            <Route exact path={`${match.path}/announcements`} component={AdminAnnouncement}/>
            <Route exact path={`${match.path}/announcements/view`} component={ViewAnnouncement}/>
            <Route path={`${match.path}/announcements/add`} component={AnnouncementDefinition}/>
            <Route exact path={`${match.path}/attendances/:approvalStatus`} component={AttendanceManagement}/>
            <Route exact path={`${match.path}/attendance/settings`} component={AttendanceSettings}/>
            <Route exact path={`${match.path}/attendance/pending-acknowledgements`}
                   component={AttendancePendingAckApprovals}/>
            <Route exact path={`${match.path}/attendance/pending`} component={PendingAttendances}/>
            <Route path={`${match.path}/attendanceedit/:action`} component={EditeAttendance}/>
            <Route exact path={`${match.path}/attendance/flagged`} component={RejectedAttendances}/>
            <Route exact path={`${match.path}/contents`} component={ContentManagement}/>
            <Route exact path={`${match.path}/contents/add`} component={ContentDefinition}/>
            <Route exact path={`${match.path}/contents/view`} component={ViewContent}/>
            <Route exact path={`${match.path}/settings`} component={Settings}/>
            <Route exact path={`${match.path}/leaveTypes/create`} component={CreateLeaves}/>
            <Route path={`${match.path}/activity-logs`} component={ActivityLog}/>
            <Route path={`${match.path}/account-settings`} component={AccountSettings}/>
        </>

    )
};
