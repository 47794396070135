import {Route, Switch} from "react-router-dom";
import React from "react";
import SideBar from "./side-bar/SideBar";
import TopBar from "./top-bar/TopBar";
import './Layout.css';
import {UserRoutes} from "./user-routes";
import {AdminRoutes} from "./admin-routes";
import {connect} from "react-redux";
import {actionGetOrganizations} from "../../actions/organization.actions";
import UserDashboard from "../saas-user/user/dashboard/UserDashboard";
import AccountSettings from "../saas-user/shared/account-settings";


class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSideNavOpen: false,
        };
    }

    componentDidMount() {
        const {selectedOrganization, location, history} = this.props;
        if (!selectedOrganization) {
            history.push({pathname: '/select-organization', state: {redirectUrl: location.pathname}});
        } else {
            this.props.actionGetOrganizations();
        }
    }

    toggleSidebar = () => {
        this.setState({isSideNavOpen: !this.state.isSideNavOpen});
    };

    render() {
        const {isSideNavOpen} = this.state;
        const {location, match} = this.props;
        return (
            <div id="wrapper" className={`d-flex ${isSideNavOpen ? 'toggled' : ''}`}>
                <SideBar {...this.props} className="shadow"/>

                <div id="page-content-wrapper" style={{position: 'relative'}}>

                    <TopBar toggleSidebar={this.toggleSidebar} {...this.props}/>

                    <div className="container-fluid mb-5">

                        <Switch>
                            {location.pathname.includes('admin') && (
                                <AdminRoutes {...this.props}/>
                            )}

                            {location.pathname.includes('user') && (
                                <UserRoutes {...this.props}/>
                            )}
                        </Switch>

                    </div>

                    <footer className="text-right bg-light border-top" style={{
                        position: 'absolute', bottom: 0, left: -1,
                        width: '100%',
                        padding: "10px 10px 10px 0"
                    }}>
                        <small className="font-weight-bold">&#169; Blue Silo Pte. Ltd.</small>
                    </footer>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({organizationReducer}) => ({
    selectedOrganization: organizationReducer.selectedOrganization
});

export default connect(mapStateToProps, ({actionGetOrganizations}))(Layout);
