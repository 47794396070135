import {adminConstants} from "../../constants";

const INITIAL_STATE = {
    organizations: [],
    organization: null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case adminConstants.CREATE_ORGANIZATION:
            return {
                ...state,
                organizations: action.payload
            };
        case adminConstants.GET_ADMIN_ORGANIZATIONS:
            return {
                ...state,
                organizations: action.payload
            };
        case adminConstants.ACTION_UPDATE_ORGANIZATION:
            return {
                ...state,

            }
        default:
            return {
                ...state
            }
    }
}
