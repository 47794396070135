import React, {useEffect, useState} from 'react';
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import {authenticationConstants, commonConstants, userConstants} from "../../../constants";
import {DropdownButton, Dropdown} from "react-bootstrap";
import CreateOrganizationModal from "../../saas-user/admin/create-organization-modal";
import Badge from "react-bootstrap/Badge";
import language from "../../../language";
import Navbar from 'react-bootstrap/Navbar'

function TopBar(props) {

    const [viewAppAs, setViewApp] = useState('');
    const [showCreateOrgModal, setShowCreateOrgModal] = useState(false);

    const dispatch = useDispatch();
    const {loggedInUser} = useSelector(state => state.authentication);
    const {organizations} = useSelector(state => state.organizationReducer);
    const {selectedOrganization} = useSelector(state => state.organizationReducer);
    const {locale, emoji} = useSelector(state => state.langReducer);
    let {location} = props;

    useEffect(() => {
        if (location.pathname.includes('user')) {
            setViewApp('user');
        } else if (location.pathname.includes('admin')) {
            setViewApp('admin');
        }
    }, [location.pathname]);


    const changeSelectedOrganization = (orgId) => {
        dispatch({type: userConstants.SET_SELECTED_ORGANIZATION, payload: orgId});
        props.history.push(`/${viewAppAs}/dashboard`);
    };

    const changeLanguage = (locale) => {
        const langObj = language[locale];
        dispatch({type: commonConstants.ACTION_GET_LANG, payload: {lang: langObj.lang, emoji: langObj.emoji, locale}});
    };

    const changeAppView = () => {
        const changeTo = viewAppAs === 'admin' ? 'user' : 'admin';
        props.history.push(`/${changeTo}/dashboard`);
    }

    const onLogout = () => {
        dispatch({type: authenticationConstants.LOG_OUT});
        props.history.push('/login');
    };

    return (
        <Navbar bg="light" className="border-bottom ">
            <button className="btn" id="menu-toggle" onClick={() => props.toggleSidebar()}>
                <i className="fas fa-bars"/>
            </button>

            <DropdownButton variant="secondary" size="sm" id="dropdown-basic-button" className="mr-1"
                            title={selectedOrganization?.name || ''}>
                {organizations.map(org => (
                    <Dropdown.Item key={org._id} onClick={() => changeSelectedOrganization(org._id)}>
                        {org.name}
                        {org._id === selectedOrganization._id &&
                        <Badge variant="success" pill className="ml-1">Active</Badge>}
                    </Dropdown.Item>
                ))}

                {loggedInUser.isOwner && (
                    <>
                        <Dropdown.Divider/>
                        <Dropdown.Item onClick={() => setShowCreateOrgModal(true)}><i className="fas fa-plus"/> New
                            Organization</Dropdown.Item>
                    </>
                )}
            </DropdownButton>
            <CreateOrganizationModal show={showCreateOrgModal} handleClose={() => setShowCreateOrgModal(false)}/>

            <DropdownButton variant="secondary" size="sm" id="lang-dropdown"
                            title={
                                <>
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '7px',
                                            left: 5
                                        }}>
                                        {emoji}
                                    </span>
                                    <span className="ml-4">{locale}</span>
                                </>}>
                <Dropdown.Item onClick={() => changeLanguage('en')}>
                    <span role="img" aria-label="flag" className="mr-1">🇺🇸</span> English (en)</Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage('zh-sg')}>
                    <span role="img" aria-label="flag" className="mr-1">🇨🇳</span> Chinese (zh-sg)</Dropdown.Item>
            </DropdownButton>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                    {/*<li className="nav-item">*/}
                    {/*    <i className="far fa-bell"/>*/}
                    {/*</li>*/}
                    {(selectedOrganization.isSuperAdmin || selectedOrganization.isAdmin) && selectedOrganization.isEmployee && (
                        <li className="nav-item d-none d-md-block">
                            <Button variant="light" onClick={changeAppView}>
                                <SwitchAppView viewAppAs={viewAppAs}/>
                            </Button>
                        </li>
                    )}


                    <NavDropdown
                        title={<> <i className="fas fa-user mr-1"/> {loggedInUser.userName} </>}
                        id="collasible-nav-dropdown">
                        <NavDropdown.Item className="d-md-none" onClick={changeAppView}>
                            <SwitchAppView viewAppAs={viewAppAs}/>
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={() => {
                            props.history.push(`/${viewAppAs}/account-settings`)
                        }}>
                            <i className="fas fa-user-cog"/> Settings
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={onLogout}>
                            <i className="fas fa-sign-out-alt"/> Sign Out
                        </NavDropdown.Item>
                    </NavDropdown>

                </ul>
            </div>
        </Navbar>
    )
}

const SwitchAppView = ({viewAppAs}) => (
    <>
        {viewAppAs === 'admin' &&
        <span> <i
            className="fas fa-exchange-alt"/> Switch
            to User</span>}
        {viewAppAs === 'user' &&
        <span> <i
            className="fas fa-exchange-alt"/> Switch
            to Admin</span>}
    </>
)

export default TopBar;


