import {trackPromise} from "react-promise-tracker";
import axios from "../axiosConfig";
import {adminConstants} from "../../constants";


export const createLeaveType = (data) => async dispatch => {
    const response = await trackPromise(axios.post(`leaves/type/create`, data));
    dispatch({
        type: adminConstants.CREATE_LEAVE_TYPE,
        payload: response.data.data
    });
};

export const updateLeaveType = (id, data) => async dispatch => {
    const response = await trackPromise(axios.put(`leaves/type/update/${id}`, data));
    dispatch({
        type: adminConstants.UPDATE_LEAVE_TYPE,
        payload: response.data.data
    })
};

export const getAllLeaveTypes = () => async dispatch => {
    const response = await trackPromise(axios.get('leaves/type/list'));
    dispatch({
        type: adminConstants.GET_ALL_LEAVE_TYPES,
        payload: response.data.data
    })
}
