import React, {useEffect, useState} from 'react';
import Select from "react-select";

export const SelectEmployees = (props) => {

    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    const {employees = [], values = [], handleSelectChange} = props;

    useEffect(() => {
        const options = employees.map(employee => ({
            value: employee._id,
            label: employee.firstName + ' ' + employee.lastName
        }));

        setSelectOptions(options);

        const result = employees.filter(employee => values.some(employeeId => employeeId === employee._id))
            .map(employee => ({
                value: employee._id,
                label: employee.firstName + ' ' + employee.lastName
            }));
        setSelectedValues(result);
    }, [employees, values]);

    const handleChange = (selected) => {
        const employeeIds = selected ? selected.map(employee => employee.value) : [];
        handleSelectChange(employeeIds);
    };

    return (
        <Select
            options={selectOptions}
            value={selectedValues}
            onChange={handleChange}
            isSearchable
            isMulti
        />
    )
};
