import React, {useCallback, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useSelector} from "react-redux";
import Col from "react-bootstrap/Col";
import {convertDateTimeToCustomFormat, convertDateToCustomFormat} from "../../../../../utlis";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker/es";
import {CustomDatePicker} from "../../../../shared/custom-date-picker";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import Badge from "react-bootstrap/Badge";
import moment from "moment";

export const AttendanceVerification = (props) => {
    const {show, handleClose, attendanceId, openAckModal} = props;
    const {selectedOrganization} = useSelector(state => state.organizationReducer);
    const {attendanceHistory} = useSelector(state => state.user.attendanceReducer);
    const {ARVO_Fields} = useSelector(state => state.user.ARVO_Reducer);

    const [canAcknowledge, setCanAcknowledge] = useState(false);
    const [attendance, setAttendance] = useState({});
    const [user, setUser] = useState({});
    const [punchInDateTime, setPunchInDateTime] = useState(new Date());
    const [punchOutDateTime, setPunchOutDateTime] = useState(new Date());
    const [comment, setComment] = useState('');
    const [flags, setFlags] = useState([]);
    const [commentsErrorMsg, setCommentsErrorMsg] = useState('');
    const [ackStatus, setAckStatus] = useState('');
    const [canApprove, setCanApprove] = useState(true);

    useEffect(() => {
        if (attendanceHistory?.length > 0) {
            const found = attendanceHistory.find(at => at._id === attendanceId);
            if (found) {
                setAttendance(found);
                setUser(found.user);
                if (found.isVerified) {
                    setPunchInDateTime(new Date(found.verification.punchIn));
                    setPunchOutDateTime(new Date(found.verification.punchOut));
                    setComment(found.verification.comment);
                    setFlags(found.verification.flag?.map(flag => ({field: flag.field, value: flag.value})));
                }
                const isSupervisor = selectedOrganization.otSupervisorOn.some(userId => userId === found.user._id);
                setCanAcknowledge(isSupervisor);

                if (!attendance.isVerified && ARVO_Fields && ARVO_Fields.length) {
                    setFlags(ARVO_Fields.map(field => ({field: field._id, value: ''})));
                }
            }
        }
    }, [attendance.isVerified, attendanceHistory, attendanceId, selectedOrganization.otSupervisorOn, ARVO_Fields]);

    const getAckStatus = useCallback(() => {
        setCanApprove(true);
        let isRejected = false;
        if ((attendance?.punchInAck && attendance.punchInAck.ack === false) || (attendance?.punchOutAck && attendance.punchOutAck.ack === false)) {
            isRejected = true;
        } else if (attendance?.breakTime?.length) {
            isRejected = attendance.breakTime.some(breakTime => (breakTime.startAck && breakTime.startAck.ack === false) || (breakTime.endAck && breakTime.endAck.ack === false));
        }

        if (isRejected) {
            return "rejected";
        } else {
            let isPending = true;
            if (attendance?.punchInAck && attendance?.punchOutAck) {
                if (attendance?.breakTime?.length) {
                    isPending = attendance.breakTime.some(breakTime => !breakTime.startAck || !breakTime.endAck);
                }
                isPending = false;
            }
            if (isPending) {
                setCanApprove(!selectedOrganization.attendanceAcknowledgementRequired);
                return "pending"
            }
            return "approved";
        }
    }, [attendance, selectedOrganization.attendanceAcknowledgementRequired]);

    useEffect(() => {

        setAckStatus(getAckStatus());
    }, [getAckStatus]);

    const getPunchInDateTime = () => {
        if (attendance.ifLate) {
            return convertDateTimeToCustomFormat(attendance?.changeRequest.adjustedTime.punchInDateTime);
        } else {
            return convertDateTimeToCustomFormat(attendance.punchInDateTime);
        }
    };

    const getPunchOutDateTime = () => {
        if (attendance.ifLate) {
            return convertDateTimeToCustomFormat(attendance.changeRequest?.adjustedTime.punchOutDateTime);
        } else {
            return convertDateTimeToCustomFormat(attendance.punchOutDateTime);
        }
    };

    const handleARVO_Change = (id, value) => {
        const newArr = [].concat(flags);
        const index = newArr.findIndex(obj => obj.field === id);
        newArr[index].value = value;
        setFlags(newArr);
    };

    const handleSubmit = (status) => {
        const actualPunchIn = getPunchInDateTime();
        const actualPunchOut = getPunchOutDateTime();
    
        if (actualPunchIn !== convertDateTimeToCustomFormat(punchInDateTime) || actualPunchOut !== convertDateTimeToCustomFormat(punchOutDateTime)) {
      if (!comment.length  ||!comment) {
        setCommentsErrorMsg("To modify attendance timings, comments are required");
        return;
      } 
    }      
        const data = {punchIn: punchInDateTime, punchOut: punchOutDateTime, comment, status, flag: flags};
        props.handleVerifyAttendance(attendanceId, data);
        setCommentsErrorMsg("");
        setComment("");
    };

    return (
        <Modal show={show} size={"lg"} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Attendance Record Verification
                    <span className="ml-1">
                    {attendance.isVerified ? <i className="fas fa-check-circle text-success"/> :
                        <i className="fas fa-question-circle"/>}
                    </span>


                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="form-group">
                    <Col md={6}>
                        <label>Attendance Date</label>
                        <input className="form-control"
                               value={convertDateToCustomFormat(attendance.createdAt)} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Acknowledgement Status</label>
                        {ackStatus === 'pending' && (
                            <>
                                <h5><Badge variant="light">Pending</Badge></h5>
                                <small className="btn-link cursor-pointer" onClick={openAckModal}>
                                    Click here to complete acknowledgement
                                </small>
                            </>
                        )}
                        {ackStatus === 'rejected' && <h5><Badge variant="danger">Rejected</Badge></h5>}
                        {ackStatus === 'approved' && <h5><Badge variant="success">Approved</Badge></h5>}
                    </Col>
                </Row>
                <Row className="form-group">
                    <Col md={6}>
                        <label>Employee Username</label>
                        <input className="form-control" value={`${user.userName}`} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Employee Name</label>
                        <input className="form-control" value={`${user.firstName} ${user.lastName}`} readOnly/>
                    </Col>
                </Row>

                <Row className="form-group">
                    <Col md={6}>
                        <label>Actual PunchIn Time</label>
                        <input className="form-control"
                               value={getPunchInDateTime()}
                               readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Actual PunchOut Time</label>
                        <input className="form-control" readOnly
                               value={attendance.punchOutDateTime ? getPunchOutDateTime() : '----'}/>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col md={6}>
                        <label>Approved PunchIn Time</label>
                        <DatePicker
                            dateFormat="dd-MM-yyyy HH:mm"
                            timeInputLabel="Time:"
                            showTimeInput
                            selected={punchInDateTime}
                            onChange={date => setPunchInDateTime(date)}
                            customInput={<CustomDatePicker/>}
                            withPortal
                            disabled={attendance.isVerified}
                        />
                    </Col>
                    <Col md={6}>
                        <label>Approved PunchOut Time</label>
                        <DatePicker
                            dateFormat="dd-MM-yyyy HH:mm"
                            timeInputLabel="Time:"
                            showTimeInput
                            selected={punchOutDateTime}
                            onChange={date => setPunchOutDateTime(date)}
                            customInput={<CustomDatePicker/>}
                            popperPlacement="bottom-end"
                            withPortal
                            disabled={attendance.isVerified}
                        />
                    </Col>

                    <Col>
                        <label>Comments</label>
                        <textarea
                            className="form-control"
                            value={comment}
                            onChange={event => setComment(event.target.value)}
                            readOnly={attendance.isVerified}
                        />
                        <small className="text-danger">{commentsErrorMsg}</small>
                    </Col>
                </Row>

                {ARVO_Fields?.map(field => (
                    <Accordion className="mt-1" key={field._id}>
                        <Card>
                            <Card.Header>
                                <div className="d-flex">
                                    <span className="flex-grow-1 align-self-center">{field.name}</span>
                                    <div className="mr-1 align-content-center d-flex">
                                        <span className="mr-1">{field.prefix}</span>
                                        {field.type === 'boolean' && (
                                            <Toggle
                                                icons={false}
                                                defaultChecked={false}
                                                onChange={(e) => handleARVO_Change(field._id, e.target.value)}/>
                                        )}
                                        {field.type === 'text' && (
                                            <input type="text"/>
                                        )}
                                        <span className="ml-1">{field.suffix}</span>
                                    </div>

                                    <Accordion.Toggle as={Button} variant="default" size="sm" eventKey={field._id}>
                                        <i className="fas fa-chevron-down"/>
                                    </Accordion.Toggle>

                                </div>

                            </Card.Header>
                            <Accordion.Collapse eventKey={field._id}>
                                <Card.Body>{field.description}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                ))}

                {attendance.isVerified && (
                    <div className="mt-2">
                        {attendance.verification?.status === 'approved' ? <Badge variant="success">Approved by</Badge> :
                            <Badge variant="danger">Rejected by</Badge>}
                        <span className="font-weight-bold ml-1">
                            {attendance.verification?.supervisor.userName}
                        </span>
                        <small className="d-block">
                            {convertDateTimeToCustomFormat(attendance.verification?.createdAt)}
                        </small>
                    </div>
                )}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleClose}>
                    Close
                </Button>
                {canApprove && !attendance.isVerified &&
                <>
                    <Button variant="danger" size="sm" onClick={() => handleSubmit('rejected')}>
                        Reject
                    </Button>
                    <Button variant="success" size="sm" onClick={() => handleSubmit('approved')}>
                        Approve
                    </Button>
                </>
                }

            </Modal.Footer>
        </Modal>
    );
};
