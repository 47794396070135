import React from 'react';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import {Formik} from "formik";
import * as Yup from 'yup';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {getGroups} from "../../../../actions/admin/group.actions";
import {getEmployees} from '../../../../actions/admin/employee.actions';
import {actionsCreateAnnouncement} from "../../../../actions/admin/announcement.actions";
import {connect} from "react-redux";
import {SelectGroups} from "../select-groups";
import Select from "react-select";
import {SelectEmployees} from "../select-employees";
import Button from "react-bootstrap/Button";
import {TextEditor} from "../../../shared/text-editor";


class AnnouncementDefinition extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formInitialValues: {...initialEmptyFormValues},
            announcementForOptions: [
                {value: 'groups', label: 'Groups'},
                {value: 'employees', label: 'Employees'}
            ],
        }
    }

    componentDidMount() {
        this.props.getGroups();
        this.props.getEmployees();
    }

    handleSubmit = async (values) => {
        try {
            const data = {...values, type: 'announcement'};
            await this.props.actionsCreateAnnouncement(data);
            this.props.history.push('/admin/announcements');

        } catch (error) {
            console.log(error);
        }
    };

    handleDatePickerValidationColor = (error) => {
        let classes = 'form-control';
        if (error) {
            classes += ' border-danger'
        }
        return classes;
    };

    render() {
        const {formInitialValues, announcementForOptions} = this.state;
        const {groups, employees, lang} = this.props;
        return (
            <Container fluid className="mt-4 mb-5">
                <Card>
                    <Card.Body>
                        <Card.Title>{lang.AddAnnouncement}</Card.Title>
                        <Formik
                            initialValues={formInitialValues}
                            validationSchema={validationSchema}
                            enableReinitialize={true}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  handleSubmit,
                                  handleChange,
                                  setFieldValue,
                                  values,
                                  errors
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group controlId="title">
                                        <Form.Label>{lang.Title}</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name={"title"}
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Group as={Col} md={6} controlId="formDate">
                                            <Form.Label>{lang.FromDate}</Form.Label>
                                            <DatePicker
                                                selected={values.fromDate ? new Date(values.fromDate) : values.fromDate}
                                                onChange={(date) => setFieldValue('fromDate', date)}
                                                dateFormat="dd-MMM-yyyy"
                                                className={this.handleDatePickerValidationColor(errors.fromDate)}
                                            />
                                            <small className="text-danger">{errors.fromDate}</small>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="toDate">
                                            <Form.Label>{lang.ToDate}</Form.Label>
                                            <DatePicker
                                                selected={values.tillDate ? new Date(values.tillDate) : values.tillDate}
                                                onChange={(date) => setFieldValue('tillDate', date)}
                                                dateFormat="dd-MMM-yyyy"
                                                className="form-control"/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label>{lang.AnnouncementFor}</Form.Label>
                                            <Select
                                                options={announcementForOptions}
                                                defaultValue={announcementForOptions[0]}
                                                onChange={(selected) => {
                                                    setFieldValue('announcementFor', selected.value);
                                                    if (selected.value === 'groups') setFieldValue('targetUser', []);
                                                    else if (selected.value === 'employees') setFieldValue('targetGroup', []);
                                                }}
                                            />
                                        </Form.Group>
                                        {values.announcementFor === 'groups' && (
                                            <Form.Group as={Col} md={8}>
                                                <Form.Label>{lang.GroupsLists}</Form.Label>
                                                <SelectGroups groups={groups} values={values.targetGroup}
                                                              handleSelectChange={selected => setFieldValue('targetGroup', selected)}/>
                                                <small className="text-danger">{errors.targetGroup}</small>
                                            </Form.Group>
                                        )}

                                        {values.announcementFor === 'employees' && (
                                            <Form.Group as={Col} md={8}>
                                                <Form.Label>{lang.EmployeesLists}</Form.Label>
                                                <SelectEmployees employees={employees} values={values.targetUser}
                                                                 handleSelectChange={selected => setFieldValue('targetUser', selected)}/>
                                                <small className="text-danger">{errors.targetUser}</small>
                                            </Form.Group>
                                        )}
                                    </Form.Row>

                                    <Form.Group>
                                        <Form.Label>{lang.Description}</Form.Label>
                                        <TextEditor
                                            value={values.description}
                                            onBlur={newContent => setFieldValue('description', newContent)}/>
                                    </Form.Group>
                                    <Button className="mt-3" type="submit">{lang.Add}</Button>
                                </Form>
                            )}

                        </Formik>
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = ({admin: {groupReducer, employeeReducer}, langReducer}) => ({
    groups: groupReducer.groups,
    employees: employeeReducer.employees,
    lang: langReducer.lang
});

export default connect(mapStateToProps, {getGroups, getEmployees, actionsCreateAnnouncement})(AnnouncementDefinition);

const initialEmptyFormValues = {
    title: '',
    description: '',
    fromDate: '',
    tillDate: '',
    priorityLevel: 0,
    targetGroup: [],
    targetUser: [],
    announcementFor: 'groups'
};

const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    fromDate: Yup.string().required('From date is required'),
    announcementFor: Yup.string(),
    targetGroup: Yup.array().of(Yup.string()).when('announcementFor', {
        is: 'groups',
        then: Yup.array().of(Yup.string()).required("Please select at least one group")
    }),
    targetUser: Yup.array().of(Yup.string()).when('announcementFor', {
        is: 'employees',
        then: Yup.array().of(Yup.string()).required("Please select at least one employee")
    })

});
