import {adminConstants} from "../../constants";

const initialState = {
    employees: [],
    employee: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case adminConstants.GET_EMPLOYEES:
            return {
                ...state,
                employees: action.payload
            };
        case adminConstants.GET_EMPLOYEE:
            return {
                ...state,
                employee: action.payload
            };
   
        case adminConstants.UPDATE_EMPLOYEE:
            return {
                ...state,
                employees: state.employees.map(employee => employee._id === action.payload._id ? (employee = action.payload) : employee)
            };
        case adminConstants.ADD_EMPLOYEE:
            return {
                ...state,
                employees: [action.payload, ...state.employees]
            };

        case adminConstants.DELETE_EMPLOYEE:
            return {
                ...state,
                employees: state.groups.filter(group => group._id !== action.payload)
            };

        default:
            return state;
    }
}
