import React from 'react';
import {connect} from "react-redux";
import DataTable from 'react-data-table-component';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/esm/Button";
import Badge from "react-bootstrap/Badge";
import {getOutlets} from "../../../../actions/admin/outlet.actions";

class OutletsList extends React.Component {

    componentDidMount() {
        this.props.getOutlets();
    }

    editOutlet = (outletId) => () => {
        this.props.history.push(`/admin/outlets/edit?outletId=${outletId}`)
    };

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.Name,
                selector: 'name',
                sortable: true,
            },
            {
                name: lang.Comments,
                selector: 'comment',
                sortable: true,
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>  {row.status === 'A' ? <Badge variant="success">{lang.Active}</Badge> :
                        <Badge variant="danger">{lang.Inactive}</Badge>}
                    </h6>
                )
            },
            {
                name: '',
                button: true,
                cell: row => (
                    <>
                        <Button variant="info" size="sm" className="mr-1" onClick={this.editOutlet(row._id)}><i
                            className="fas fa-wrench"/></Button>
                        {/*<Button variant="danger" size="sm"><i className="far fa-trash-alt"/></Button>*/}
                    </>)
            }
        ];
    };

    render() {
        const {outlets, lang} = this.props;
        return (
            <Container fluid className="pt-4">
                <Card>
                    <Card.Body>
                        <DataTable
                            title={lang.AllOutlets}
                            columns={this.renderColumns()}
                            data={outlets}
                            pagination={true}
                        />
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = ({admin: {outletReducer}, langReducer}) => ({
    outlets: outletReducer.outlets,
    lang: langReducer.lang
});


export default connect(mapStateToProps, ({getOutlets}))(OutletsList);
